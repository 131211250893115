import classes from './styles.module.scss';

import { unfollowPage, useReactMutation, useTranslation } from '@lobox/utils';
import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type UnfollowCallProps = {
  id: string;
  name: string;
  username: string;
  needsAlert?: boolean;
};

type UseUnfollowPageType = Omit<UseMutationResult, 'mutate'> & {
  unfollowCall: (props: UnfollowCallProps, opt?: UseMutationOptions) => void;
};

const useUnfollowPage = (): UseUnfollowPageType => {
  const { t } = useTranslation();
  const { openConfirmDialog } = useOpenConfirm();
  const { mutate, ...rest } = useReactMutation({
    apiFunc: unfollowPage,
  });

  const apiCall = (id: string, options?: UseMutationOptions) => () => {
    mutate({ id }, options);
  };
  const unfollowCall = (
    { id, name, username, needsAlert = true }: UnfollowCallProps,
    options?: UseMutationOptions
  ) => {
    if (needsAlert) {
      openConfirmDialog({
        title: (
          <Flex className={classes.messageWrap}>
            <Typography>{t('unfollow')}</Typography>
            <Typography font="bold" ml={4} isWordWrap>
              {name}
            </Typography>
          </Flex>
        ),
        message: `${t('unfollow_question')} @${username}${t('q_mrk')}`,
        confirmButtonText: t('unfollow'),
        cancelButtonText: t('cancel'),
        confirmCallback: apiCall(id, options),
      });
    } else {
      apiCall(id, options)();
    }
  };

  return {
    unfollowCall,
    ...rest,
  };
};

export default useUnfollowPage;
