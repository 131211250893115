import { acceptFollow, useReactMutation } from '@lobox/utils';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

type UseAcceptFollowType = Omit<UseMutationResult, 'mutate'> & {
  acceptCall: (id: string, opt?: UseMutationOptions) => void;
};

const useAcceptFollow = (): UseAcceptFollowType => {
  const { mutate, ...rest } = useReactMutation({
    apiFunc: acceptFollow,
  });

  const acceptCall = (id: string, options?: UseMutationOptions) => {
    mutate(
      {
        id,
      }, // @ts-ignore
      options
    );
  };

  return {
    acceptCall,
    ...rest,
  };
};

export default useAcceptFollow;
