import { followPage, useReactMutation } from '@lobox/utils';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

type UseFollowPageType = Omit<UseMutationResult, 'mutate'> & {
  followCall: (id: string, opt?: UseMutationOptions) => void;
};

const useFollowPage = (): UseFollowPageType => {
  const { mutate, ...rest } = useReactMutation({
    apiFunc: followPage,
  });

  const followCall = (id: string, options?: UseMutationOptions) => {
    mutate(
      {
        id,
      }, // @ts-ignore
      options
    );
  };

  return {
    followCall,
    ...rest,
  };
};

export default useFollowPage;
