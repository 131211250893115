import { followHashtag, useReactMutation } from '@lobox/utils';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

type UseFollowHashtagType = Omit<UseMutationResult, 'mutate'> & {
  followCall: (id: string, opt?: UseMutationOptions) => void;
};

const useFollowHashtag = (): UseFollowHashtagType => {
  const { mutate, ...rest } = useReactMutation({
    apiFunc: followHashtag,
  });

  const followCall = (id: string, options?: UseMutationOptions) => {
    mutate(
      {
        id,
      }, // @ts-ignore
      options
    );
  };

  return {
    followCall,
    ...rest,
  };
};

export default useFollowHashtag;
