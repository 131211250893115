import React from 'react';
import Typography from '@lobox/uikit/Typography';
import type { TypographyProps } from '@lobox/uikit/Typography';

interface ModalTitleProps extends TypographyProps {
  className?: string;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ children, ...rest }) => (
  <Typography size={24} height={28} font="bold" color="smoke_coal" {...rest}>
    {children}
  </Typography>
);

export default ModalTitle;
