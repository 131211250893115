import classes from './Item.skeleton.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Skeleton from '@lobox/uikit/Skeleton';

export interface ItemSkeletonProps {
  className?: string;
  isPage?: boolean;
}

const ItemSkeleton: React.FC<ItemSkeletonProps> = ({ className, isPage }) => {
  return (
    <Flex className={cnj(classes.avatarWrapper, className)}>
      <Skeleton
        className={cnj(classes.skeleton__1, isPage && classes.skeleton__1_p)}
      />
      <Skeleton className={classes.skeleton__2} />
    </Flex>
  );
};

export default ItemSkeleton;
