import classes from './Simple.component.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import Tooltip from '@lobox/uikit/Tooltip';
import Typography from '@lobox/uikit/Typography';
import isEqual from 'lodash/isEqual';
import type { ModalHeaderBaseProps } from '../Base';
import BaseHeader from '../Base';
import Title from '../../components/ModalTitle';

export interface ModalHeaderSimpleProps extends ModalHeaderBaseProps {
  title?: string | JSX.Element;
  titleProps?: { [key: string]: any };
  helper?: string | React.ReactNode;
  rightContent?: () => React.ReactNode;
  modalHeaderProps?: {
    [key: string]: any;
  };
  helperProps?: {
    containerClassName?: string;
    [key: string]: any;
  };
  wrapperRef?: React.MutableRefObject<HTMLElement>;
  onTitleClick?: () => any;
}

const ModalHeaderSimple: React.FC<ModalHeaderSimpleProps> = ({
  title,
  helper,
  titleProps,
  helperProps,
  rightContent,
  ...rest
}) => {
  const { containerClassName, ...restHelperProps } = helperProps || {};
  return (
    <BaseHeader {...rest}>
      <Flex className={cnj(classes.leftContainer)}>
        {!!title &&
          (typeof title === 'string' ? (
            <Title {...titleProps}>{title}</Title>
          ) : (
            title
          ))}
        {helper ? (
          <Flex className={cnj(classes.helperContainer, containerClassName)}>
            <Tooltip
              placement="top"
              trigger={
                <Icon
                  color="primaryText"
                  type="far"
                  name="info-circle"
                  size={20}
                  {...restHelperProps}
                />
              }
            >
              <Typography size={14} font="400" color="tooltipText">
                {helper}
              </Typography>
            </Tooltip>
          </Flex>
        ) : null}
      </Flex>
      {rightContent?.()}
    </BaseHeader>
  );
};

export default React.memo(ModalHeaderSimple, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
