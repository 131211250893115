import React, { RefObject } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import BaseBody from '../Base';
import type { ModalBodyBaseProps } from '../Base';

export type ModalBodyProps = ModalBodyBaseProps;

const ModalBody = ({
  children,
  className,
  ...rest
}: ModalBodyProps, ref: any) => (
  <BaseBody
    className={cnj(className)}
    ref={ref}
    {...rest}
  >
    {children}
  </BaseBody>
);

export default React.forwardRef(ModalBody);
