import React from 'react';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import {
  PAGE_ROLES,
  preventClickHandler,
  QueryKeys,
  useUpdateQueryData,
} from '@lobox/utils';
import openBusinessApp from '@shared/utils/openBusiness';
import type { DataReturnType } from '@shared/hooks/api-hook/useGetMyPages';

interface CardNotificationItemProps {
  messagesNumber: number | string;
  notificationNumber: number | string;
  id: string;
}

const CardNotificationItem = ({
  messagesNumber,
  notificationNumber,
  id,
}: CardNotificationItemProps): JSX.Element => {
  const { get } = useUpdateQueryData<DataReturnType>([QueryKeys.myPages]);
  const item: DataReturnType = get(id);
  const isUnpublished = item?.page?.status === 'UNPUBLISHED';
  const canEditProfile = [
    PAGE_ROLES.ADMIN.value,
    PAGE_ROLES.EDITOR.value,
    PAGE_ROLES.RECRUITER.value,
  ].includes(item?.role as any);

  const handleClickBadge = (e: any) => {
    preventClickHandler(e);
    openBusinessApp(item?.page?.username);
  };

  return (
    <Flex flexDir="row">
      {canEditProfile && !isUnpublished && (
        <IconButton
          onClick={handleClickBadge}
          size="md18"
          colorSchema="primary"
          name="envelope"
          type="far"
          badgeNumber={messagesNumber}
        />
      )}
      {!isUnpublished && (
        <IconButton
          onClick={handleClickBadge}
          size="md18"
          colorSchema="primary"
          name="bell"
          type="far"
          badgeNumber={notificationNumber}
        />
      )}
    </Flex>
  );
};

export default CardNotificationItem;
