import classes from './index.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import type ColorsKeys from '@lobox/uikit/ColorsKeys';
import type { ButtonProps } from '@lobox/uikit/Button';

interface UnderlinedButton extends ButtonProps {
  underLineColor?: ColorsKeys;
}

const UnderlinedButton: React.FC<UnderlinedButton> = ({
  underLineColor = 'brand',
  ...rest
}) => {
  return (
    <Button
      variant="thin"
      schema="ghost-brand"
      rightIcon="chevron-right"
      {...rest}
      className={cnj(
        classes.underline,
        classes[`textDecorationColor-${underLineColor}`],
        rest?.className
      )}
    />
  );
};

export default UnderlinedButton;
