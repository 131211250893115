import { getPageMembers, QueryKeys, useReactQuery } from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import type { UseQueryResult } from '@tanstack/react-query';
import type { RoleType, PageMemberStatusType } from '@lobox/utils';

export type UseGetPageMembersProps = {
  enabled?: boolean;
  businessPageId?: string;
};

export type PageMemberType = {
  id: string;
  role: RoleType;
  user: {
    id: string;
    fullName: string;
    username: string;
    image: string;
    job: string;
  };
  pageId: string;
  status: PageMemberStatusType;
};

export type UseGetPageMembersType = UseQueryResult<
  Array<PageMemberType>,
  any
> & {
  getPageMembersKey: Array<string>;
};

const useGetPageMembers = ({
  enabled,
  businessPageId,
}: UseGetPageMembersProps): UseGetPageMembersType => {
  const { businessPage } = useGetAppObject();
  const pageId = businessPageId || businessPage?.id;
  const getPageMembersKey = [QueryKeys.pageMembers, pageId];

  const queryResult = useReactQuery<Array<PageMemberType>>({
    action: {
      apiFunc: getPageMembers,
      key: getPageMembersKey,
      params: { pageId },
    },
    config: {
      enabled,
    },
  });

  return {
    ...queryResult,
    getPageMembersKey,
  };
};

export default useGetPageMembers;
