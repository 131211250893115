import classes from './UserItem.component.module.scss';

import React, { Fragment } from 'react';
import Avatar from '@lobox/uikit/Avatar';
import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import Flex from '@lobox/uikit/Flex';
import Link from '@lobox/uikit/Link';
import type { TypographyProps } from '@lobox/uikit/Typography';
import type { AvatarProps } from '@lobox/uikit/Avatar';
import type { ButtonProps } from '@lobox/uikit/Button';

interface ListItemWithActionProps {
  data: {
    image?: string;
    title?: string;
    subTitle?: string;
    to?: string;
  };
  avatarProps?: AvatarProps;
  titleProps?: Omit<TypographyProps, 'children'>;
  subTitleProps?: Omit<TypographyProps, 'children'>;
  action?: React.ReactNode;
  containerProps?: ButtonProps;
  noHover?: boolean;
  textsContainerClassName?: string;
}
const ListItemWithAction = ({
  data,
  avatarProps,
  titleProps,
  subTitleProps,
  action,
  containerProps = {},
  noHover,
  textsContainerClassName,
}: ListItemWithActionProps): JSX.Element => {
  const { image, title, subTitle } = data;
  const ToWrapper: any = data?.to ? Link : Fragment;
  const toWrapperProps = data?.to ? { to: data.to } : {};

  return (
    <BaseButton
      {...containerProps}
      className={cnj(
        classes.itemContainer,
        noHover && classes.noHover,
        containerProps?.className
      )}
    >
      <ToWrapper {...toWrapperProps}>
        <Avatar
          size="md"
          imgSrc={image}
          className={classes.avatar}
          {...avatarProps}
        />
      </ToWrapper>
      <Flex className={cnj(classes.textsContainer, textsContainerClassName)}>
        <ToWrapper {...toWrapperProps}>
          <Typography color="thirdText" font="bold" size={16} {...titleProps}>
            {title}
          </Typography>
        </ToWrapper>
        <ToWrapper {...toWrapperProps}>
          <Typography size={14} color="brand" {...subTitleProps}>
            {subTitle}
          </Typography>
        </ToWrapper>
      </Flex>
      {action}
    </BaseButton>
  );
};

export default ListItemWithAction;
