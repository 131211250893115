import classes from './Section.component.module.scss';

import React, { Fragment, useRef } from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import DividerVertical from '@lobox/uikit/Divider/DividerVertical';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import Link from '@lobox/uikit/Link';
import SearchInput from '@lobox/uikit/SearchInput';
import Typography from '@lobox/uikit/Typography';
import useHorizontalScroll from '@lobox/uikit/utils/useHorizontalScroll';
import { useTranslation } from '@lobox/utils';
import UnderlinedButton from '@shared/components/molecules/UnderlinedButton';
import type { IconButtonProps } from '@lobox/uikit/Button/IconButton';

export type ActionType = {
  label: string;
  iconName?: string;
  rightIcon?: string;
  to?: string;
  onClick?: (...args: any[]) => any;
};

export interface ObjectSectionContainerProps {
  className?: string;
  titleClassName?: string;
  headerClassName?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  title?: string;
  seeMoreLink?: string;
  resultsNumber?: number | string;
  resultsNumberThreshold?: number;
  resultsCaption?: string;
  actionsList?: Array<ActionType>;
  backAction?: (...args: any[]) => any;
  backButtonSchema?: IconButtonColorSchema;
  noHead?: boolean;
  subheaderClassName?: string;
  iconButtonProps?: IconButtonProps;
  onSearchChanged?: (text: string) => void;
  wrapperProps?: Record<any, any>;
  hasSearchInput?: boolean;
}

const ObjectSectionContainer = ({
  className,
  titleClassName,
  headerClassName,
  title,
  seeMoreLink,
  resultsNumber,
  resultsNumberThreshold = 0,
  resultsCaption,
  children,
  actionsList = [],
  backAction,
  backButtonSchema = 'transparent',
  noHead,
  subheaderClassName,
  iconButtonProps,
  hasSearchInput,
  onSearchChanged,
  wrapperProps,
}: ObjectSectionContainerProps): JSX.Element => {
  const { t } = useTranslation();
  const titleWrapperRef = useRef<HTMLElement | undefined>();
  const doesHaveHeader =
    !noHead &&
    !!(
      title ||
      backAction ||
      actionsList?.length ||
      seeMoreLink ||
      resultsNumber
    );
  const SeeMoreWrapper = seeMoreLink ? Link : Fragment;
  const seeMoreProps: any = seeMoreLink ? { to: seeMoreLink } : {};
  useHorizontalScroll(titleWrapperRef);

  return (
    <Flex className={className} {...wrapperProps}>
      {doesHaveHeader && (
        <Flex className={cnj(classes.header, headerClassName)}>
          <Flex
            ref={titleWrapperRef}
            flexDir="row"
            className={cnj(classes.subheader, subheaderClassName)}
          >
            {backAction && (
              <IconButton
                className={classes.backActionButton}
                colorSchema={backButtonSchema}
                size="md"
                name="chevron-left"
                type="fas"
                onClick={backAction}
                {...iconButtonProps}
              />
            )}
            <Flex className={classes.titleAndResultNumberWrapper}>
              {!!title && (
                <Typography
                  font="bold"
                  size={20}
                  color="primaryText"
                  className={cnj(classes.title, titleClassName)}
                  isTruncated
                  height={23}
                >
                  {title}
                </Typography>
              )}
              {(seeMoreLink || resultsCaption) &&
                (resultsNumber || 0) > resultsNumberThreshold && (
                  <>
                    <DividerVertical className={classes.divider} height={20} />
                    <SeeMoreWrapper {...seeMoreProps}>
                      <Typography
                        className={classes.resultNumber}
                        noWrap
                        size={14}
                        color="colorIconForth"
                        height={16}
                      >
                        {typeof resultsNumber === 'number' && seeMoreLink
                          ? `${t('see')} ${
                              (resultsNumber || 0) - resultsNumberThreshold
                            } ${t('more_sm')}`
                          : typeof resultsNumber === 'string' || resultsCaption
                            ? `${resultsNumber} ${resultsCaption || ''}`
                            : ''}
                      </Typography>
                    </SeeMoreWrapper>
                  </>
                )}
            </Flex>

            {actionsList?.length === 1 && (
              <Flex className={classes.actionsListWrapper}>
                {actionsList.map(
                  ({ label, rightIcon, iconName, onClick, to }) => (
                    <UnderlinedButton
                      key={label}
                      schema="ghost-brand"
                      leftIcon={iconName}
                      rightIcon={rightIcon}
                      leftColor="brand"
                      label={label}
                      onClick={onClick}
                      to={to}
                      className={classes.singleActionButton}
                    />
                  )
                )}
              </Flex>
            )}
            {actionsList?.length > 1
              ? actionsList.map(({ label, iconName, onClick }, index) => (
                  <Fragment key={label + iconName}>
                    <DividerVertical
                      height={20}
                      className={cnj(
                        classes.actionsDivider,
                        index === 0 && classes.firstActionDivider
                      )}
                    />
                    <Button
                      schema="ghost-brand"
                      leftIcon={iconName}
                      leftColor="brand"
                      label={label}
                      className={cnj(
                        classes.actionButton,
                        index === 0 && classes.firstActionButton
                      )}
                      labelClassName={classes.actionLabel}
                      leftIconClassName={classes.leftIcon}
                      onClick={onClick}
                    />
                  </Fragment>
                ))
              : null}
          </Flex>
          {hasSearchInput && (
            <SearchInput
              size="small"
              onChange={(text: string) => onSearchChanged?.(text)}
              placeholder={t('search')}
              className={classes.inputRootClassName}
            />
          )}
        </Flex>
      )}
      {children}
    </Flex>
  );
};

export default ObjectSectionContainer;
