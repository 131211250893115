import classes from './FixedRightSideModalDialog.component.module.scss';

import React, { useRef } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import { useClickOutside } from '@lobox/utils';
import {
  AUTO_COMPLETE_OPTIONS_ID,
  TOP_BAR_ITEM,
} from '@shared/constants/enums';
import type { ModalDialogProps } from '../BasicModal/Modal.Dialog';
import ModalDialog from '../BasicModal/Modal.Dialog';

type FixedRightSideModalDialog = Partial<ModalDialogProps> & {
  onClickOutside?: (e: MouseEvent) => void;
};

const FixedRightSideModalDialog: React.FC<FixedRightSideModalDialog> = ({
  children,
  onClickOutside,
  ...rest
}) => {
  const modalBodyRef = useRef<HTMLDivElement>(null);

  useClickOutside(modalBodyRef, {
    onClickOutside: (e) => {
      const shouldIgnore = [
        ...Array.from(document.getElementsByClassName(TOP_BAR_ITEM)),
        document.getElementById(AUTO_COMPLETE_OPTIONS_ID),
      ]?.some((node) => node?.contains(e?.target));
      if (shouldIgnore) return;

      onClickOutside(e);
    },
    skip: !onClickOutside,
  });

  return (
    <ModalDialog
      isOpen
      {...rest}
      backdropClassName={cnj(classes.backdrop, rest.backdropClassName)}
      modalClassName={cnj(classes.wrapper, rest.modalClassName)}
      modalDialogClassName={classes.content}
      contentClassName={classes.modalRoot}
    >
      <div ref={modalBodyRef} style={{ display: 'contents' }}>
        {children}
      </div>
    </ModalDialog>
  );
};

export default FixedRightSideModalDialog;
