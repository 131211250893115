import React from 'react';
import Button from '@lobox/uikit/Button';
import { preventClickHandler, useTranslation } from '@lobox/utils';
import { roomMemberRoles } from '@shared/components/Organism/Message/constants';
import useOpenMessage from '@shared/hooks/useOpenMessage';
import type { ButtonProps } from '@lobox/uikit/Button';

interface SendMessageButtonProps extends ButtonProps {
  className?: string;
  object: {
    id: string;
    croppedImageUrl: string;
    fullName: string;
    username: string;
    isPage?: boolean;
  };
}

const SendMessageButton: React.FC<SendMessageButtonProps> = ({
  className,
  object,
  ...rest
}) => {
  const { t } = useTranslation();
  const openMessage = useOpenMessage();

  const openMessageHandler = (e: any) => {
    preventClickHandler(e);
    openMessage({
      isGroupChat: false,
      id: object.id,
      icon: object.croppedImageUrl,
      name: object.fullName,
      owner: object.id,
      username: object.username,
      createdAt: new Date(),
      role: roomMemberRoles.Owner,
      isPage: object.isPage,
    });
  };

  return (
    <Button
      schema="semi-transparent"
      leftIcon="envelope"
      leftType="far"
      label={t('message')}
      className={className}
      onClick={openMessageHandler}
      {...rest}
    />
  );
};

export default SendMessageButton;
