import classes from './PendingRequestItem.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import useTheme from '@lobox/uikit/utils/useTheme';
import {
  preventClickHandler,
  QueryKeys,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import useCancelFollow from '@shared/hooks/api-hook/useCancelFollow';
import { useQueryClient } from '@tanstack/react-query';

export interface PendingRequestItemProps {
  object?: {
    id: string;
    username: string;
  };
  className?: string;
  onSuccess?: (data: any, variables?: void, context?: unknown) => void;
}

const PendingRequestItem = ({
  object,
  onSuccess,
  className,
}: PendingRequestItemProps): JSX.Element => {
  const { t } = useTranslation();
  const { isDark } = useTheme();

  const { cancelCall, isLoading } = useCancelFollow();
  const { replace } = useUpdateQueryData([
    QueryKeys.objectDetail,
    object?.username,
  ]);
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData([
    QueryKeys.objectDetail,
    object?.username,
  ]);
  const handleCancel = (e: any) => {
    preventClickHandler(e);
    if (userData) {
      replace({
        network: {
          ...userData.network,
          follow: false,
          followStatus: null,
        },
      });
    }
    cancelCall(object?.id, {
      onSuccess,
    });
  };

  return (
    <Flex
      flexDir="row"
      className={cnj(classes.followRequestItemRoot, className)}
    >
      <Button
        schema="ghost-orange"
        label={t('pending')}
        className={cnj(classes.button, classes.disabledButton)}
        disabled
      />
      <Button
        isLoading={isLoading}
        schema={isDark ? 'secondary-dark' : 'semi-transparent'}
        leftIcon="times"
        label={t('cancel')}
        className={classes.button}
        onClick={handleCancel}
      />
    </Flex>
  );
};

export default PendingRequestItem;
