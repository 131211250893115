import classes from './NoticeBoxWithItemAction.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import NoticeBox from '@shared/components/molecules/NoticeBox/NoticeBox';
import NoticeBoxItem from '@shared/components/molecules/NoticeBox/NoticeBox.Item';
import { useTranslation } from '@lobox/utils';

interface NoticeBoxWithItemActionProps {
  layoutProps: {
    title: string;
    onMoreClicked?: () => void;
    isVisibleShowMore?: boolean;
    footerActionText?: string;
  };
  data: any[];
  dataKeys: {
    imageKeyName?: string;
    titleKeyName?: string;
    subTitleKeyName?: string;
  };
  listItemProps?: any;
  actionRender?: (...args: any[]) => React.ReactNode;
  isLoading?: boolean;
  isEmpty?: boolean;
}

const NoticeBoxWithItemAction: React.FC<NoticeBoxWithItemActionProps> = ({
  data,
  listItemProps,
  layoutProps,
  dataKeys,
  actionRender,
  isLoading,
  isEmpty,
}) => {
  const { t } = useTranslation();

  return (
    <NoticeBox
      title={layoutProps?.title}
      onMoreClicked={layoutProps?.onMoreClicked}
      isVisibleShowMore={layoutProps?.isVisibleShowMore}
      isLoading={isLoading}
      footerActionText={layoutProps?.footerActionText}
    >
      <Flex className={classes.listContainer}>
        {isEmpty ? (
          <Typography className={classes.emptyMessage} size={16} height={20}>
            {t('empty_msg')}
          </Typography>
        ) : (
          data?.map((item) => (
            <NoticeBoxItem
              className={classes.listItem}
              key={item.id}
              avatarProps={{ isCompany: item?.isPage }}
              data={{
                ...item,
                objectId: item.id,
                image: dataKeys.imageKeyName
                  ? item[dataKeys.imageKeyName]
                  : null,
                title: dataKeys.titleKeyName
                  ? item[dataKeys.titleKeyName]
                  : null,
                subTitle: dataKeys.subTitleKeyName
                  ? item[dataKeys.subTitleKeyName]
                  : null,
              }}
              actions={
                actionRender ? (
                  <Flex className={classes.listItemActionsContainer}>
                    {actionRender(item)}
                  </Flex>
                ) : undefined
              }
              {...listItemProps}
            />
          ))
        )}
      </Flex>
    </NoticeBox>
  );
};

export default NoticeBoxWithItemAction;
