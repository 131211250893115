import { useGlobalDispatch, useTranslation } from '@lobox/utils';
import useObjectNetwork from './api-hook/useObjectNetwork';
import type {
  PageType,
  PeopleType,
  FollowStatusType,
  PageApiResponseType,
} from '@lobox/utils';
import { followStatus } from '../constants/enums';
import useShareEntity from './useShareEntity';

type CardObjectProps = (PageType | PeopleType | PageApiResponseType) & {
  title?: string;
  name?: string;
  surname?: string;
  followStatus?: FollowStatusType;
  unfollowSuccess?: (...args: any[]) => any;
  block?: (...args: any[]) => any;
  category?: { value: string; label: string };
};

type UseCardMenuBuilderType = (
  cardObject: CardObjectProps
) => Array<MoreMenuItemType>;

export const MoreMenuKeys = {
  shareVP: 'shareVp',
  shareMSG: 'shareMSG',
  remove: 'remove',
  unfollow: 'unfollow',
  block: 'block',
  report: 'report',
};

export const PageOwnersForbiddenActions = [
  MoreMenuKeys.report,
  MoreMenuKeys.block,
];

export type MoreMenuItemType = {
  key: keyof typeof MoreMenuKeys;
  iconName: string;
  label: string;
  onClick: (...args: any[]) => any;
};

const useCardMenuBuilder = (isPage?: boolean): UseCardMenuBuilderType => {
  const { t } = useTranslation();
  const { unfollowHandler: unfollow, isLoading: isLoadingUnfollow } =
    useObjectNetwork();
  const appDispatch = useGlobalDispatch();

  const { onClick: onShare } = useShareEntity();

  return (cardObject: CardObjectProps): Array<MoreMenuItemType> => {
    const doYouFollowIt = // @ts-ignore
      cardObject.follow || cardObject.pageNetworkModel?.follow;
    const doesItFollowBack = // @ts-ignore
      cardObject.back || cardObject.pageNetworkModel?.back;

    const SHARE_VIA_MESSAGE = {
      key: MoreMenuKeys.shareMSG,
      iconName: 'share',
      label: t(isPage ? 'share_page' : 'share_profile'),
      onClick: () => {
        onShare({
          username: cardObject?.username,
          type: isPage ? 'page' : 'profile',
          tabs: [
            'copy_link',
            'share_via_post',
            'share_via_message',
            'share_via_email',
          ],
        });
      },
    };
    /* const REMOVE = {
      key: MoreMenuKeys.remove,
      iconName: 'user-minus',
      label: t('remove_f_followers'),
      onClick: removeFromFollowers,
    } */
    const UNFOLLOW = {
      key: MoreMenuKeys.unfollow,
      iconName: 'user-times',
      label: t('unfollow'),
      isLoading: isLoadingUnfollow,
      onClick: () =>
        unfollow(
          {
            isPage,
            id: cardObject.id,
            name: isPage
              ? (cardObject.title as string)
              : `${cardObject.name} ${cardObject.surname}`,
            username: cardObject.username as string,
          },
          {
            onSuccess: cardObject.unfollowSuccess,
          }
        ),
    };
    const BLOCK = {
      key: MoreMenuKeys.block,
      iconName: 'ban',
      label: t('block'),
      onClick: () => {
        if (typeof cardObject?.block === 'function') {
          cardObject?.block();
        }
      },
    };
    const REPORT = {
      key: MoreMenuKeys.report,
      iconName: 'pennant',
      label: isPage ? t('report_page') : t('report_user'),
      onClick: () => {
        appDispatch({
          type: 'TOGGLE_REPORT_MODAL',
          payload: {
            isOpen: true,
            data: {
              entityType: isPage ? 'page' : 'user',
              entityId: cardObject.id,
            },
          },
        });
      },
    };

    let result;

    if (
      cardObject.followStatus === followStatus.PENDING ||
      (!doYouFollowIt && !doesItFollowBack)
    ) {
      result = [SHARE_VIA_MESSAGE, BLOCK, REPORT];
    } else if (doYouFollowIt && !doesItFollowBack) {
      result = [SHARE_VIA_MESSAGE, UNFOLLOW, BLOCK, REPORT];
    } else if (doYouFollowIt && doesItFollowBack) {
      result = [
        SHARE_VIA_MESSAGE,
        // REMOVE,
        UNFOLLOW,
        BLOCK,
        REPORT,
      ];
    } else if (!doYouFollowIt && doesItFollowBack) {
      result = [
        SHARE_VIA_MESSAGE,
        // REMOVE,
        BLOCK,
        REPORT,
      ];
    }

    return result?.filter(Boolean) as Array<MoreMenuItemType>;
  };
};

export default useCardMenuBuilder;
