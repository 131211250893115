import classes from './ModalBodyImage.component.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';

export interface ModalBodyImageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  image: React.ReactNode;
  className?: string;
  bottomDivider?: boolean;
  dividerProps?: {
    [key: string]: any;
  };
}

const ModalBodyImage: React.FC<ModalBodyImageProps> = ({
  image,
  className,
  dividerProps,
  bottomDivider,
  ...rest
}) => {
  return (
    <>
      <Flex
        className={cnj(classes.gradientImageContainer, className)}
        {...rest}
      >
        {image?.()}
      </Flex>

      {bottomDivider && (
        <Divider className={classes.divider} {...dividerProps} />
      )}
    </>
  );
};

export default ModalBodyImage;
