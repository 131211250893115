import classes from './index.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import { useModalContext } from '../components/context';

export interface ModalDialogProps {
  onBackdropClick?: (e: React.SyntheticEvent) => void;
  variant?: 'simple' | 'gradient';
  isHidden?: boolean;
  modalDialogClassName?: string;
  contentClassName?: string;
  modalClassName?: string;
  children: React.ReactNode;
  htmlId?: string;
}

const BasicDialogModal: React.FC<ModalDialogProps> = ({
  isHidden = false,
  onBackdropClick,
  modalDialogClassName,
  contentClassName,
  modalClassName,
  variant = 'simple',
  children,
  htmlId,
}) => {
  const { handleCloseModal } = useModalContext();

  const handleBackdropClick = () => {
    if (onBackdropClick) {
      onBackdropClick();
    } else {
      handleCloseModal();
    }
  };

  return (
    <Flex
      id={htmlId}
      className={cnj(
        'm-d',
        classes.modal,
        isHidden && classes.modalhidden,
        modalClassName
      )}
      onClick={handleBackdropClick}
    >
      <Flex
        className={cnj(classes.modalDialog, modalDialogClassName)}
        onClick={(e: any) => e.stopPropagation()}
      >
        <Flex
          className={cnj(classes.modalContent, contentClassName)}
          onClick={(e: any) => e.stopPropagation()}
        >
          {variant === 'gradient' && (
            <Flex
              className={classes.gradiant}
              onClick={(e: any) => e.stopPropagation()}
            />
          )}
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BasicDialogModal;
