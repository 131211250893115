import React from 'react';
import Button from '@lobox/uikit/Button';
import {
  preventClickHandler,
  QueryKeys,
  useAuthState,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import useObjectNetwork from '@shared/hooks/api-hook/useObjectNetwork';
import callbacksExecutor from '@shared/utils/callbacksExecutor';
import { useQueryClient } from '@tanstack/react-query';
import urls from '@shared/constants/urls';
import type { QueryKeyType } from '@lobox/utils';
import type ButtonSchemaType from '@lobox/uikit/ButtonSchemaType';

interface FollowProps {
  className?: string;
  back?: boolean;
  isLoading?: boolean;
  schema?: ButtonSchemaType;
  onSuccess?: (args: any) => void;
  onFollowHandler?: (args: any) => void;
  object: {
    isPage?: boolean;
    id: string;
    username: string;
  };
  queryKey?: QueryKeyType;
}

const FollowButton = ({
  back,
  isLoading,
  schema,
  className,
  object,
  onSuccess,
  onFollowHandler,
  queryKey,
}: FollowProps): JSX.Element => {
  const { t } = useTranslation();
  const { followHandler, isLoading: isLoadingFollow } = useObjectNetwork();
  const queryClient = useQueryClient();
  const { replace } = useUpdateQueryData(
    queryKey || [QueryKeys.objectDetail, object?.username]
  );
  const userData = queryClient.getQueryData(
    queryKey || [QueryKeys.objectDetail, object?.username]
  );
  const isLoggedIn = useAuthState('isLoggedIn');

  const replaceUserData = (res: any) => {
    if (userData) {
      replace({
        network: {
          ...userData.network,
          follow: true,
          followStatus: res?.followStatus,
          followersCounter:
            res?.followStatus === 'ACCEPTED' || object?.isPage
              ? Number(userData.network?.followersCounter) + 1
              : userData.network?.followersCounter,
        },
      });
    }
  };

  const onClickHandler = (e: any) => {
    preventClickHandler(e);
    if (!isLoggedIn) {
      return window.location.replace(
        `${urls.login}?redirect=/${object?.username}`
      );
    }

    if (onFollowHandler) {
      onFollowHandler(e);
    } else {
      followHandler(object, {
        onSuccess: callbacksExecutor(onSuccess, replaceUserData),
      });
    }
  };

  return (
    <Button
      isLoading={isLoading || isLoadingFollow}
      className={className}
      fullWidth
      schema={schema || 'primary-blue'}
      leftIcon={isLoggedIn ? 'plus' : 'sign-in-alt'}
      leftType={isLoggedIn ? 'fas' : undefined}
      label={
        !isLoggedIn
          ? t('login_to_follow')
          : back
            ? t('follow_back')
            : t('follow_cap')
      }
      onClick={onClickHandler}
    />
  );
};

export default FollowButton;
