import React from 'react';
import useGetRole from '@shared/hooks/useGetRole';
import PERMISSIONS from '@shared/contexts/permission-maps';
import hasPermission from '@shared/utils/hasPermission';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import type { ScopeType } from '@shared/types/permission';
import type { RoleType } from '@lobox/utils';

export interface PermissionsGateProps {
  scopes: Array<ScopeType>;
  renderFallback?: JSX.Element;
  children?: ReactNode;
}

const PermissionsGate: React.FC<PermissionsGateProps> = ({
  children,
  scopes,
  renderFallback = <></>,
}) => {
  const { roles } = useGetRole();
  // only business app has role and permission;
  if (!isBusinessApp || !roles?.length) {
    return <>{children}</>;
  }
  const permissions = roles.reduce(
    (prev: RoleType[], curr: RoleType) => [...prev, ...PERMISSIONS[curr]],
    []
  );
  const permissionGranted = hasPermission({ permissions, scopes });

  if (!permissionGranted) return renderFallback;

  return <>{children}</>;
};

export default PermissionsGate;
