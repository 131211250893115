import { useEffect } from 'react';
import { preventClickHandler } from '@lobox/utils';
import type { MutableRefObject } from 'react';

const useHorizontalScroll = (
  scrollWrapperRef?: MutableRefObject<HTMLElement | undefined>,
  scrollSpeed = 1
): void => {
  useEffect(() => {
    const horizWrapper = scrollWrapperRef?.current;
    if (horizWrapper?.style) {
      horizWrapper.style.overflow = 'auto';
      horizWrapper.style.flexShrink = '0';
    }
    let isDown = false;
    let startX: number;
    let scrollLeft: number;
    let initiationTime: InstanceType<typeof Date>;

    horizWrapper?.addEventListener(
      'mousedown',
      (e: any) => {
        isDown = true;
        startX = e.pageX - horizWrapper?.offsetLeft;
        scrollLeft = horizWrapper?.scrollLeft;
        initiationTime = new Date();
      },
      false
    );
    horizWrapper?.addEventListener(
      'mouseleave',
      () => {
        isDown = false;
      },
      false
    );
    horizWrapper?.addEventListener(
      'mouseup',
      () => {
        isDown = false;
      },
      false
    );
    horizWrapper?.addEventListener(
      'mousemove',
      (e: any) => {
        if (!isDown) return;
        e.preventDefault();

        const x = e.pageX - horizWrapper?.offsetLeft;
        const walk = (x - startX) * scrollSpeed;

        horizWrapper.scrollLeft = scrollLeft - walk;
      },
      false
    );
    horizWrapper?.addEventListener(
      'click',
      (e: any) => {
        const x = e.pageX - horizWrapper?.offsetLeft;
        const walk = (x - startX) * scrollSpeed;
        const now = new Date();
        const timeSpent = Math.abs(now.getTime() - initiationTime.getTime());
        if (Math.abs(walk) < 10 || timeSpent < 100) return;
        preventClickHandler(e);
      },
      true
    );
  }, [scrollSpeed, scrollWrapperRef]);
};

export default useHorizontalScroll;
