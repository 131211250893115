import { cancelFollowRequest, useReactMutation } from '@lobox/utils';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

type UseCancelFollowType = Omit<UseMutationResult, 'mutate'> & {
  cancelCall: (id: string, opt?: UseMutationOptions) => void;
};

const useCancelFollow = (): UseCancelFollowType => {
  const { mutate, ...rest } = useReactMutation({
    apiFunc: cancelFollowRequest,
  });

  const cancelCall = (id: string, options?: UseMutationOptions) => {
    mutate(
      {
        id,
      }, // @ts-ignore
      options
    );
  };

  return {
    cancelCall,
    ...rest,
  };
};

export default useCancelFollow;
