import classes from './Base.component.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import IconButton from '@lobox/uikit/Button/IconButton';
import { useModalContext } from '../../components/context';
import { omit } from 'lodash';

export interface ModalHeaderBaseProps {
  className?: string;
  hideBack?: boolean;
  visibleHeaderDivider?: boolean;
  modalHeaderProps?: {
    [key: string]: any;
  };
  backButtonProps?: {
    [key: string]: any;
  };
  closeButtonProps?: {
    [key: string]: any;
  };
  wrapperRef?: React.MutableRefObject<HTMLElement>;
  onTitleClick?: () => any;
  noCloseButton?: boolean;
  children?: ReactNode;
}

const ModalHeaderBase: React.FC<ModalHeaderBaseProps> = ({
  className,
  hideBack = true,
  modalHeaderProps,
  backButtonProps,
  closeButtonProps,
  children,
  wrapperRef,
  visibleHeaderDivider = false,
  onTitleClick,
  noCloseButton,
}) => {
  const { handleCloseModal, onBack } = useModalContext();

  const { onClick: onBackClick, ...rest } = backButtonProps || {};
  return (
    <>
      <Flex
        className={cnj(
          classes.modalHeader,
          visibleHeaderDivider && classes.divider,
          className
        )}
        {...modalHeaderProps}
        ref={wrapperRef}
      >
        <IconButton
          onClick={onBackClick || onBack}
          name="chevron-left"
          type="far"
          colorSchema="transparentSmokeCoal"
          {...rest}
          className={cnj(
            classes.backBtn,
            hideBack && classes.backBtnHidden,
            backButtonProps?.className
          )}
        />

        <Flex className={classes.headerContent} onClick={onTitleClick}>
          {children}
        </Flex>
        {!noCloseButton && (
          <IconButton
            colorSchema="transparentSmokeCoal"
            onClick={handleCloseModal}
            name="times"
            type="far"
            size="md"
            {...closeButtonProps}
            className={cnj(classes.closeBtn, closeButtonProps?.className)}
          />
        )}
      </Flex>
    </>
  );
};

export default ModalHeaderBase;
