import classes from './index.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import Icon from '@lobox/uikit/Icon';
import { makeDynamicStyles } from '@shared/uikit/utils/makeDynamicStyles';

interface TextWithIconProps {
  text: string;
  icon: string;
  iconProps?: any;
  textProps?: any;
  className?: string;
  schema?: keyof typeof colorSchemaMapperTable;
  size?: number;
}

const colorSchemaMapperTable = {
  base: {
    background: 'transparent',
    iconColor: 'thirdText',
  },
  success: {
    background: 'success_5',
    iconColor: 'mint',
  },
};

const TextWithIcon: React.FC<TextWithIconProps> = ({
  text,
  icon,
  iconProps = {},
  textProps = {},
  className,
  schema: selectedSchema,
  size = 32,
}) => {
  const schema =
    // @ts-ignore
    colorSchemaMapperTable?.[selectedSchema] || colorSchemaMapperTable?.base;

  return (
    <Flex flexDir="row" className={cnj(classes.container, className)}>
      <Flex
        className={cnj(
          classes.iconWrapper,
          classes[`backgroundColor-${schema?.background}`]
        )}
        {...makeDynamicStyles({
          width: size,
          height: size,
          minHeight: size,
          minWidth: size,
        })}
      >
        <Icon
          type="far"
          color={schema?.iconColor}
          size={18}
          name={icon}
          {...iconProps}
        />
      </Flex>
      <Typography ml={8} color="thirdText" {...textProps}>
        {text}
      </Typography>
    </Flex>
  );
};

export default TextWithIcon;
