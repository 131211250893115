import classes from './index.module.scss';

import React from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import DividerVertical from '@lobox/uikit/Divider/DividerVertical';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';

interface HorizontalInfoBoxProps {
  data?: Array<{ onClick?: () => void; title: string; value: string }>;
  className?: string;
  itemClassName?: string;
  direction?: 'row' | 'col';
}

const HorizontalInfoBox = ({
  className,
  data,
  itemClassName,
  direction,
}: HorizontalInfoBoxProps) => {
  const isRow = direction === 'row';

  return (
    <Flex className={className} flexDir="row">
      {data?.map(({ onClick, title, value }, index: number) => (
        <Flex className={cnj(classes.itemWrapper, itemClassName)} key={title}>
          <BaseButton
            className={cnj(
              onClick ? classes.itemClassname : undefined,
              isRow && classes.rowItemClassName
            )}
            disabled={!onClick}
            onClick={onClick}
          >
            <Typography
              isWordWrap
              isTruncated
              lineNumber={1}
              font="400"
              size={12}
              height={18}
              color="border"
              className={cnj(classes.follower, isRow && classes.rowFollower)}
            >
              {title}
            </Typography>
            <Typography
              isWordWrap
              isTruncated
              lineNumber={1}
              font="700"
              size={15}
              height={21}
              color="thirdText"
            >
              {value}
            </Typography>
          </BaseButton>
          {index < data.length - 1 && (
            <DividerVertical color="techGray_10" className={classes.divider} />
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default HorizontalInfoBox;
