import React from 'react';
import Button from '@lobox/uikit/Button';
import useTheme from '@lobox/uikit/utils/useTheme';
import { useTranslation } from '@lobox/utils';

interface ApplyProps {
  className?: string;
}

const Apply: React.FC<ApplyProps> = ({ className }) => {
  const { t } = useTranslation();
  const { isDark } = useTheme();

  return (
    <Button
      schema={isDark ? 'secondary-dark' : 'semi-transparent'}
      className={className}
      label={t('apply')}
      leftIcon="user-check"
      leftType="fas"
    />
  );
};

export default Apply;
