import classes from './SimpleTab.component.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import SimpleTabItem from './SimpleTab.item';
import type { TabItemModelProps } from './SimpleTab.item';

interface SimpleTabProps {
  tabs: TabItemModelProps[];
  activeTab?: TabItemModelProps;
  onChange?: (tab: any) => void;
  tabContainerClassName?: string;
  itemContainerClassName?: string;
}

const SimpleTab: React.FC<SimpleTabProps> = ({
  tabs = [],
  activeTab,
  onChange,
  tabContainerClassName,
  itemContainerClassName,
}) => {
  const [selectedTab, setTab] = React.useState(activeTab);
  const handleClick = (tab: any) => {
    setTab(tab);
    onChange?.(tab);
  };
  return (
    <Flex className={cnj(classes.container, tabContainerClassName)}>
      {tabs.map((item) => (
        <SimpleTabItem
          onClick={handleClick}
          key={item.id}
          data={item}
          isActive={selectedTab?.id === item.id}
          itemContainerClassName={itemContainerClassName}
        />
      ))}
    </Flex>
  );
};

export default SimpleTab;
