import classes from './index.module.scss';

import React from 'react';
import Avatar from '@lobox/uikit/Avatar';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import Typography from '@lobox/uikit/Typography';
import partial from 'lodash/partial';

interface LinkAccountItemProps {
  onClick: () => void;
  isSelected: boolean;
  item: {
    key: string;
    title: string;
    image?: string;
  };
  className?: string;
}

const LinkAccountItem: React.FC<LinkAccountItemProps> = ({
  onClick,
  isSelected,
  item,
  className,
}) => {
  return (
    <BaseButton
      onClick={partial(onClick, item)}
      className={cnj(classes.socialItemRoot, className)}
    >
      <Flex
        flexDir="row"
        className={cnj(classes.socialItem, isSelected && classes.selectedItem)}
      >
        <Avatar size="sm" />
        <Typography ml={6} height={21}>
          {item.title}
        </Typography>
        {isSelected && (
          <Icon
            name="check-circle"
            color="success"
            size={15}
            className={classes.doneIcon}
          />
        )}
      </Flex>
    </BaseButton>
  );
};

export default LinkAccountItem;
