import React from 'react';
import HeroIcon from '@lobox/uikit/HeroIcon';

export interface HashtagIconProps {
  className?: string;
  iconClassName?: string;
  followed?: boolean;
  info?: boolean;
  size?: number;
  isLoading?: boolean;
}

const HashtagIcon: React.FC<HashtagIconProps> = ({
  className,
  iconClassName,
  followed,
  info,
  isLoading,
  size = 32,
}) => {
  const props = isLoading
    ? { color: 'hover' }
    : {
        iconName: 'hashtag',
        color: info ? 'brand' : followed ? 'green' : 'orange',
        iconType: 'fas',
      };

  return (
    // @ts-ignore
    <HeroIcon
      className={className}
      iconClassName={iconClassName}
      size={size}
      iconSize={18}
      {...props}
    />
  );
};

export default HashtagIcon;
