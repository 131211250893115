import classes from './Tab.component.module.scss';

import React from 'react';
import type { ModalHeaderBaseProps } from '../Base';
import BasicHeader from '../Base';
import SimpleTab from '../../../../Tab/Simple';

interface ModalHeaderWithTabProps extends ModalHeaderBaseProps {
  tabs: any[];
  activeTab?: any;
  onTabChange?: (tab: any) => void;
}

const ModalHeaderWithTab: React.FC<ModalHeaderWithTabProps> = (props) => {
  const { tabs, onTabChange, activeTab, ...rest } = props;
  return (
    <BasicHeader {...rest} className={classes.customBaseHeader}>
      <SimpleTab
        tabs={tabs}
        activeTab={activeTab || tabs?.[0]}
        onChange={onTabChange && onTabChange}
      />
    </BasicHeader>
  );
};

export default ModalHeaderWithTab;

// Example
// const { isOpen: isOpenTabModal, onClose: onCloseTabModal, onOpen: onOpenTabModal} = useDisclosure();
// const tabs = [
//   { id: 1, text: 'tab1' },
//   { id: 2, text: 'tab2' },
// ];
// const [currentTab, setTab] = React.useState(tabs[0]);
// {isOpenTabModal && (
//   <ModalDialog isOpen={isOpenTabModal}>
//     <ModalHeaderWithTab
//       onClose={onCloseTabModal}
//       tabs={tabs}
//       activeTab={currentTab}
//       onTabChange={(tab) => setTab(tab)}
//     />
//     <ModalBody>{currentTab?.id === 1 ? 'tab1' : 'tab2'}</ModalBody>
//   </ModalDialog>
// )}
