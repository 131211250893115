import classes from './SearchResultItem.Component.module.scss';

import React, { Fragment } from 'react';
import Avatar from '@lobox/uikit/Avatar';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import ObjectLink from '@shared/components/molecules/ObjectLink';
import { useTranslation } from '@lobox/utils';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import type { AvatarProps } from '@lobox/uikit/Avatar';
import type { HashtagIconProps } from '../HashtagIcon/HashtagIcon';
import HashtagIcon from '../HashtagIcon/HashtagIcon';
import DateLocation from './SearchResultItem.DateLocation';

export interface SearchResultItemProps {
  className?: string;
  avatar?: AvatarProps;
  hashtagIcon?: HashtagIconProps;
  firstText: string;
  secondText?: string;
  thirdText?: string;
  forthText?: React.ReactNode;
  location?: string | number;
  time?: string | number;
  action?: React.ReactNode;
  visibleDivider?: boolean;
  onClick?: () => void;
  objectId?: string;
}

const SearchResultItem = ({
  className,
  avatar,
  hashtagIcon,
  firstText,
  secondText,
  thirdText,
  forthText,
  location,
  time,
  action,
  visibleDivider,
  onClick,
  objectId,
}: SearchResultItemProps): JSX.Element => {
  const { t } = useTranslation();
  const { checkIsAuthUser, businessPage } = useGetAppObject();
  const Wrapper: any = objectId ? ObjectLink : Fragment;
  const wrapperProps = objectId ? { objectId } : {};

  const YOU = businessPage?.id === objectId || checkIsAuthUser(objectId);

  return (
    <BaseButton onClick={onClick}>
      <Flex className={classes.searchResultItemWrapper}>
        {visibleDivider && <Divider className={classes.divider} />}
        <Flex className={cnj(classes.searchResultItemContainer, className)}>
          <Flex className={classes.leftWrapper}>
            <Flex className={classes.resultInfo}>
              {avatar && (
                <Wrapper {...wrapperProps}>
                  <Avatar {...avatar} className={classes.avatar} />
                </Wrapper>
              )}
              {hashtagIcon && (
                <HashtagIcon {...hashtagIcon} className={classes.hashtagIcon} />
              )}
              <Flex className={classes.infoWrapperSearch}>
                <Wrapper {...wrapperProps}>
                  <Typography
                    className={classes.inline}
                    font="700"
                    size={20}
                    height={23}
                    color="thirdText"
                    isWordWrap
                  >
                    {firstText}
                  </Typography>
                </Wrapper>
                {!!secondText && (
                  <Wrapper {...wrapperProps}>
                    <Typography
                      size={14}
                      height={18}
                      color="secondaryDisabledText"
                      isWordWrap
                    >
                      {secondText}
                    </Typography>
                  </Wrapper>
                )}
                {!!thirdText && (
                  <Typography size={14} height={18} mt={8} isWordWrap>
                    {thirdText}
                  </Typography>
                )}
                {!!forthText && (
                  <Flex className={classes.forthTextWrapper}>{forthText}</Flex>
                )}
              </Flex>
            </Flex>
            <DateLocation time={time} location={location} />
          </Flex>
          {action && <Flex className={classes.actionWrapper}>{action}</Flex>}
        </Flex>
      </Flex>
    </BaseButton>
  );
};

export default SearchResultItem;
