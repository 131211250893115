import { routeNames, useGlobalDispatch } from '@lobox/utils';
import { useHistory } from '@lobox/utils';
import type { IChatRoom } from '@shared/types/messaging';
import useMedia from '@lobox/uikit/utils/useMedia';

const useOpenMessage = () => {
  const history = useHistory();
  const dispatch = useGlobalDispatch();
  const { isMoreThanTablet } = useMedia();

  const openHandler = (room: IChatRoom) => {
    dispatch({ type: 'SET_DEFAULT_ACTIVE_ROOM', payload: { room } });
    if (isMoreThanTablet) {
      dispatch({ type: 'OPEN_MESSAGE_PANEL' });
    } else {
      history.push(routeNames.messages);
    }
  };
  return openHandler;
};

export default useOpenMessage;
