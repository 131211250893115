import classes from './SimpleTab.item.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';

export interface TabItemModelProps {
  id: string;
  text?: string;
  render?: React.ReactNode | string;
}

export interface SimpleTabItemProps {
  data: TabItemModelProps;
  onClick?: (tab: TabItemModelProps) => void;
  isActive?: boolean;
  itemContainerClassName?: string;
}

const SimpleTabItem: React.FC<SimpleTabItemProps> = ({
  data,
  isActive,
  itemContainerClassName,
  onClick,
}) => {
  const { text, render } = data;
  return (
    <Flex
      className={cnj(
        classes.itemContainer,
        itemContainerClassName,
        isActive && classes.active
      )}
      onClick={() => onClick?.(data)}
    >
      {render || (
        <Typography
          font={isActive ? 'bold' : '400'}
          size={15}
          color={isActive ? 'brand' : 'thirdText'}
        >
          {text}
        </Typography>
      )}
    </Flex>
  );
};

export default SimpleTabItem;
