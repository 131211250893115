import classes from './ModalFooter.component.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';

export interface ModalFooterProps {
  className?: string;
  children?: ReactNode;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ children, className }) => {
  return (
    <Flex className={cnj('modalFooter', classes.modalFooter, className)}>
      {children}
    </Flex>
  );
};

export default ModalFooter;
