import classes from './Action.component.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import BasicHeader from '../Base';
import type { ModalHeaderBaseProps } from '../Base';
import Title from '../../components/ModalTitle';

interface ModalHeaderWithActionProps extends ModalHeaderBaseProps {
  title?: string;
  titleProps?: { [key: string]: any };
  renderAction: React.ReactNode;
}

const ModalHeaderWithAction: React.FC<ModalHeaderWithActionProps> = (props) => {
  const { title, titleProps, renderAction, ...rest } = props;

  return (
    <BasicHeader {...rest}>
      <Title {...titleProps}>{title}</Title>
      {!!renderAction && <Flex className={classes.verticalLine} />}
      {!!renderAction && renderAction}
    </BasicHeader>
  );
};

export default ModalHeaderWithAction;
