import classes from './FollowersItem.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import { preventClickHandler } from '@lobox/utils';
import { FollowButton, FollowingButton } from '@shared/components/molecules';
import SendMessageButton from '@shared/components/molecules/SendMessageButton';
import type { QueryKeyType } from '@lobox/utils';

export interface FollowItemProps {
  className?: string;
  onClickSendMessage?: (item: any) => void;
  onSuccess?: (args: any) => void;
  queryKey: QueryKeyType;
  object: {
    isPage?: boolean;
    id: string;
    username: string;
    name: string;
    croppedImageUrl: string;
    fullName: string;
    isFollowed?: boolean;
    back?: boolean;
  };
}

const FollowersItem = ({
  className,
  onSuccess,
  object,
  queryKey,
}: FollowItemProps): JSX.Element => {
  const { isFollowed = true } = object || {};

  return (
    <Flex flexDir="row" className={cnj(classes.followersItemRoot, className)}>
      <SendMessageButton
        className={cnj(classes.fullWidth, classes.eachButton)}
        object={object}
      />
      <Flex className={classes.divider} />
      <Flex className={classes.eachButton}>
        {isFollowed ? (
          <FollowingButton
            queryKey={queryKey}
            object={object}
            onSuccess={onSuccess}
            onClick={preventClickHandler}
          />
        ) : (
          <FollowButton
            queryKey={queryKey}
            schema="primary-blue"
            object={object}
            onSuccess={onSuccess}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default FollowersItem;
