import useFollowUser from './useFollowUser';
import useFollowPage from './useFollowPage';
import type { UnfollowCallProps } from './useUnfollowUser';
import useUnfollowUser from './useUnfollowUser';
import useUnfollowPage from './useUnfollowPage';
import type { UseMutationOptions } from '@tanstack/react-query';
import type { PeopleFollowResponseType } from '@lobox/utils';

export interface FollowHandlerType {
  id: string;
  isPage?: boolean;
}

interface UseObjectNetworkTypes {
  followHandler: (
    { id, isPage }: FollowHandlerType,
    options?: UseMutationOptions<PeopleFollowResponseType>
  ) => void;
  unfollowHandler: (
    props: UnfollowCallProps,
    options?: UseMutationOptions
  ) => void;
  isLoading: boolean;
}

const useObjectNetwork = (): UseObjectNetworkTypes => {
  const { followCall: followUser, isLoading: isLoadingFollowUser } =
    useFollowUser();
  const { followCall: followPage, isLoading: isLoadingFollowPage } =
    useFollowPage();
  const { unfollowCall: unFollowUser, isLoading: isLoadingUnfollowUser } =
    useUnfollowUser();
  const { unfollowCall: unFollowPage, isLoading: isLoadingUnfollowPage } =
    useUnfollowPage();

  const followHandler = (
    { id, isPage }: FollowHandlerType,
    options?: UseMutationOptions<PeopleFollowResponseType>
  ) => (isPage ? followPage(id, options as any) : followUser(id, options));

  const unfollowHandler = (
    props: UnfollowCallProps,
    options?: UseMutationOptions
  ) =>
    props.isPage ? unFollowPage(props, options) : unFollowUser(props, options);

  return {
    followHandler,
    unfollowHandler,
    isLoading:
      isLoadingFollowUser ||
      isLoadingFollowPage ||
      isLoadingUnfollowUser ||
      isLoadingUnfollowPage,
  };
};

export default useObjectNetwork;
