import React, { useEffect } from 'react';
import { routeNames } from '@lobox/utils';
import Link from './Link.component';
import cnj from '../utils/cnj';
import useObjectLink from '../utils/useObjectLink';
import classes from './Link.object.module.scss';
import { useRouter } from 'next/navigation';

export interface IObjectLink extends React.PropsWithChildren {
  children?: ReactNode;
  className?: string;
  objectId: string;
  enableHover?: boolean;
  onSuccess?: (...args: any[]) => void;
  onFailure?: (...args: any[]) => void;
  username: string;
}

const ObjectLink: React.FC<IObjectLink> = ({
  className,
  objectId,
  children,
  onSuccess,
  onFailure,
  enableHover,
  username,
}) => {
  const router = useRouter();
  const handleClick = useObjectLink({ objectId, onFailure, onSuccess });

  useEffect(() => {
    if (username) {
      router.prefetch(`/${username}`);
    } else {
      console.warn('pass the username to prefetch the profile');
    }
  }, [username]);

  return (
    <Link
      prefetch={false}
      className={cnj(className, enableHover && classes.hover)}
      to={routeNames.middleScreen.makeRoute(objectId)}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

export default ObjectLink;
