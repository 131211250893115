import classes from './CategoryHeader.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import { useHistory } from '@lobox/utils';
import type { IconButtonProps } from '@lobox/uikit/Button/IconButton';
import Section from '../Section';

export interface CategoryHeaderProps {
  className?: string;
  title?: string;
  visibleOverlay?: boolean;
  hasSearchInput?: boolean;
  sectionClassName?: string;
  cover?: string;
  onSearchChanged?: (arg: string) => void;
  subheaderClassName?: string;
  headerClassName?: string;
  titleClassName?: string;
  iconButtonProps?: IconButtonProps;
}

const CategoryHeader: React.FC<CategoryHeaderProps> = ({
  className,
  title,
  visibleOverlay,
  hasSearchInput,
  sectionClassName,
  cover,
  onSearchChanged,
  subheaderClassName,
  headerClassName,
  titleClassName,
  iconButtonProps,
}) => {
  const history = useHistory();

  return (
    <Flex className={cnj(classes.categoryHeaderRoot, className)}>
      <Section
        title={title}
        className={cnj(
          classes.section,
          visibleOverlay && cover && classes.visibleOverlay,
          sectionClassName
        )}
        headerClassName={cnj(classes.sectionHeader, headerClassName)}
        titleClassName={cnj(classes.titleClassName, titleClassName)}
        backAction={() => history.goBack()}
        backButtonSchema="secondary"
        subheaderClassName={subheaderClassName}
        iconButtonProps={iconButtonProps}
        wrapperProps={{
          ...(cover && {
            style: {
              background: `url(${cover}) no-repeat center / cover`,
            },
          }),
        }}
      />
    </Flex>
  );
};

export default CategoryHeader;
