import classes from './SearchResultItem.DateLocation.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import ListItem from './SearchResultItem.ListItem';

export interface DateLocationProps {
  time?: string | number;
  location?: string | number;
}

const DateLocation: React.FC<DateLocationProps> = ({ time, location }) => {
  return time || location ? (
    <Flex className={classes.dateLocationRoot}>
      {time && <ListItem iconName="history" label={time} />}
      {location && (
        <ListItem
          iconName="map-marker-alt"
          label={location}
          className={classes.location}
        />
      )}
    </Flex>
  ) : null;
};

export default DateLocation;
