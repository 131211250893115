import { declineFollow, useReactMutation } from '@lobox/utils';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

type UseDeclineFollowType = Omit<UseMutationResult, 'mutate'> & {
  declineCall: (id: string, opt?: UseMutationOptions) => void;
};

const useDeclineFollow = (): UseDeclineFollowType => {
  const { mutate, ...rest } = useReactMutation({
    apiFunc: declineFollow,
  });

  const declineCall = (id: string, options?: UseMutationOptions) => {
    mutate(
      {
        id,
      }, // @ts-ignore
      options
    );
  };

  return {
    declineCall,
    ...rest,
  };
};

export default useDeclineFollow;
