import { unfollowHashtag, useReactMutation } from '@lobox/utils';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

type UseUnfollowHashtagType = Omit<UseMutationResult, 'mutate'> & {
  unfollowCall: (id: string, opt?: UseMutationOptions) => void;
};

const useUnfollowHashtag = (): UseUnfollowHashtagType => {
  const { mutate, ...rest } = useReactMutation({
    apiFunc: unfollowHashtag,
  });

  const unfollowCall = (id: string, options?: UseMutationOptions) => {
    mutate(
      {
        id,
      }, // @ts-ignore
      options
    );
  };

  return {
    unfollowCall,
    ...rest,
  };
};

export default useUnfollowHashtag;
