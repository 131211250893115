import React from 'react';
import OLink from '@lobox/uikit/Link/ObjectLink';

export interface IObjectLink extends React.PropsWithChildren {
  objectId: string;
  className?: string;
  username: string;
  enableHover?: boolean;
  onSuccess?: any;
  children?: ReactNode;
}

const ObjectLink: React.FC<IObjectLink> = ({
  objectId,
  className,
  username,
  children,
  enableHover,
  onSuccess,
}) => {
  return (
    <OLink
      objectId={objectId}
      enableHover={enableHover}
      className={className}
      username={username}
      // onFailure={handleError()}
      onSuccess={onSuccess}
    >
      {children}
    </OLink>
  );
};

export default ObjectLink;
