import { useCallback, useEffect, useState } from 'react';
import { useGetObjectDetail, useGlobalDispatch } from '@lobox/utils';
import type { PageType, UserType } from '@lobox/utils';

import type { ShareEntityTab } from '@lobox/utils/dist/types/share';

type ParamsType = {
  tabs: ShareEntityTab[];
} & (
  | {
      type: 'page';
      entityData?: PageType;
      username?: string;
    }
  | {
      type: 'profile';
      entityData?: UserType;
      username?: string;
    }
);

const useShareEntity = () => {
  const globalDispatch = useGlobalDispatch();
  const [isClicked, setIsClicked] = useState(false);

  const [data, setData] = useState<ParamsType>({
    tabs: [],
    type: undefined,
    entityData: undefined,
    username: undefined,
  });

  const { data: objectDetails, isLoading: isLoadingObjectDetails } =
    useGetObjectDetail({
      username: data?.username,
      enabled:
        isClicked &&
        (data.type === 'profile' || data.type === 'page') &&
        !data.entityData,
    });

  const onClick = (params: ParamsType) => {
    setIsClicked(true);
    setData(params);
  };

  const onShare = useCallback(
    (entityData: PageType | UserType) => {
      globalDispatch({
        type: 'SET_SHARE_ENTITY_TABBED_MODAL_DATA',
        payload: {
          isOpen: true,
          tabs: data.tabs,
          entityData: {
            attachment: {
              type: data.type,
              data: entityData,
            },
          },
        },
      });
    },
    [data, globalDispatch]
  );

  useEffect(() => {
    if (isClicked) {
      switch (data.type) {
        case 'profile':
        case 'page':
          if (isLoadingObjectDetails) {
            return;
          }

          onShare(data.entityData || objectDetails);

          break;

        default:
          break;
      }
    }
    setIsClicked(false);
  }, [data, isClicked, isLoadingObjectDetails, objectDetails, onShare]);

  return {
    onClick,
    isLoading: isLoadingObjectDetails,
  };
};

export default useShareEntity;
