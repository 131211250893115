import { followPeople, useReactMutation } from '@lobox/utils';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';
import type { PeopleFollowResponseType } from '@lobox/utils';

type UseFollowUserType = Omit<UseMutationResult, 'mutate'> & {
  followCall: (
    id: string,
    opt?: UseMutationOptions<PeopleFollowResponseType>
  ) => void;
};

const useFollowUser = (): UseFollowUserType => {
  const { mutate, ...rest } = useReactMutation({
    apiFunc: followPeople,
  });

  const followCall = (
    id: string,
    options?: UseMutationOptions<PeopleFollowResponseType>
  ) => {
    mutate(
      {
        id,
      }, // @ts-ignore
      options
    );
  };

  return {
    followCall,
    ...rest,
  };
};

export default useFollowUser;
