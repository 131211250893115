import classes from './Modal.Dialog.module.scss';

import React from 'react';
import Modal from 'react-overlays/Modal';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import { useModalCount } from '@lobox/utils';
import type { ModalProps } from 'react-overlays/Modal';
import ModalContextProvider from './components/context';
import ModalDialogContent from './ModalDialogContent';

export interface ModalDialogProps {
  htmlId?: string;
  variant?: 'simple' | 'gradient';
  animation?: boolean;
  isHidden?: boolean;
  isOpen: boolean;
  showConfirm?: boolean;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent> | null) => any;
  onBack?: (event?: React.MouseEvent<HTMLElement, MouseEvent> | null) => any;
  modalWrapperProps?: ModalProps;
  contentClassName?: string;
  backdropClassName?: string;
  modalClassName?: string;
  modalDialogClassName?: string;
  children: React.ReactNode;
}

const BasicDialogModal: React.FC<ModalDialogProps> = ({
  // animation = true,
  htmlId,
  isHidden = false,
  isOpen,
  modalWrapperProps,
  contentClassName,
  modalDialogClassName,
  backdropClassName,
  modalClassName,
  variant = 'simple',
  showConfirm,
  onBack,
  onClose,
  children,
}) => {
  useModalCount();

  return (
    <Modal
      show={isOpen}
      // onHide={handleClose}
      keyboard
      renderBackdrop={() => (
        <Flex className={cnj('m-b', classes.backdrop, backdropClassName)} />
      )}
      aria-labelledby="modal-label"
      renderDialog={() => (
        <ModalContextProvider {...{ showConfirm, onBack, onClose }}>
          <ModalDialogContent
            {...{
              variant,
              contentClassName,
              modalDialogClassName,
              modalClassName,
              isHidden,
              htmlId,
              onBackdropClick: modalWrapperProps?.onBackdropClick,
            }}
          >
            {children}
          </ModalDialogContent>
        </ModalContextProvider>
      )}
      {...modalWrapperProps}
    />
  );
};

export default BasicDialogModal;
