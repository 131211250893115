import { Cookies, enterToPage, getCookieKey, redirectUrl } from '@lobox/utils';
import urls from '@shared/constants/urls';

const openBusinessApp = async (pageUsername: string, routeName?: string) => {
  const BUSINESS_ID = getCookieKey('businessId');
  const OBJ_TOKEN_KEY = getCookieKey('businessObjToken');
  const BUS_OBJ_TOKEN = getCookieKey('businessObjToken');
  Cookies.remove(OBJ_TOKEN_KEY);
  Cookies.set(BUSINESS_ID, pageUsername);

  const DEVICE_ID = getCookieKey('deviceId');
  const deviceId = Cookies.get(DEVICE_ID);
  const pageData = await enterToPage(
    {
      username: pageUsername,
      deviceId,
    },
    { isUserSideApi: true }
  );
  if (pageData.accessToken) {
    Cookies.set(BUS_OBJ_TOKEN, JSON.stringify(pageData));
  }
  if (routeName) {
    const REDIRECT_ROUTE_NAME = getCookieKey('redirectRouteName');
    Cookies.set(REDIRECT_ROUTE_NAME, routeName);
  }
  redirectUrl(`${urls.business}/${pageUsername}`);
};

export default openBusinessApp;
