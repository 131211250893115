import {
  getUserNameByObjectId,
  preventClickHandler,
  useHistory,
} from '@lobox/utils';
import isFunction from 'lodash/isFunction';

interface UseObjectLinkProps {
  objectId: string;
  onSuccess?: (...args: any[]) => void;
  onFailure?: (...args: any[]) => void;
}

type UseObjectLinkReturnType = (e?: any) => void;

const useObjectLink = ({
  objectId,
  onSuccess,
  onFailure,
}: UseObjectLinkProps): UseObjectLinkReturnType => {
  const history = useHistory();

  return async (e: any) => {
    if (e) {
      preventClickHandler(e);
    }

    try {
      const data = await getUserNameByObjectId({ objectId });
      if (isFunction(onSuccess)) {
        onSuccess(data);
      }
      history.push(`/${data.username}`);
    } catch (error) {
      if (error?.response?.data.status === '404') {
        return history.push(`/${objectId}`);
      }
      if (isFunction(onFailure)) {
        onFailure();
      }
    }
  };
};

export default useObjectLink;
