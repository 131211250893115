import React from 'react';
import Button from '@lobox/uikit/Button';
import useTheme from '@lobox/uikit/utils/useTheme';
import {
  preventClickHandler,
  QueryKeys,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import useObjectNetwork from '@shared/hooks/api-hook/useObjectNetwork';
import isFunction from 'lodash/isFunction';
import callbacksExecutor from '@shared/utils/callbacksExecutor';
import { useQueryClient } from '@tanstack/react-query';
import type { IJob } from '@lobox/utils';
import type ButtonSchemaType from '@lobox/uikit/ButtonSchemaType';
import type { UnfollowCallProps } from '@shared/hooks/api-hook/useUnfollowUser';
import type ColorsKeys from '@lobox/uikit/ColorsKeys';

interface CommonProps {
  className?: string;
  schema?: ButtonSchemaType;
  onClick?: (e: any) => void;
}
interface ObjectProps extends CommonProps {
  onSuccess?: (args: any) => void;
  object: {
    isPage?: boolean;
    id: string;
    username: string;
    name: string;
    needsAlert?: boolean;
  };
  isLoading?: boolean;
  leftType?: 'far' | 'fas';
  leftColor?: ColorsKeys;
  labelColor?: ColorsKeys;
  queryKey?: Array<string>;
  data?: IJob;
}
interface UnfollowHandlerProps extends CommonProps {
  onUnfollowHandler: (args: any) => void;
  isLoading?: boolean;
}

const FollowingButton = ({
  className,
  schema = 'gray',
  onClick,
  onSuccess,
  object,
  onUnfollowHandler,
  isLoading,
  leftType,
  labelColor,
  leftColor,
  queryKey,
  data,
}: Partial<UnfollowHandlerProps & ObjectProps>): JSX.Element => {
  const { t } = useTranslation();
  const { unfollowHandler, isLoading: isLoadingUnfollow } = useObjectNetwork();
  const { replace } = useUpdateQueryData(
    queryKey || [QueryKeys.objectDetail, object?.username]
  );
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData(
    queryKey || [QueryKeys.objectDetail, object?.username]
  );
  const replaceUserData = () => {
    if (data) {
      replace({
        networkModel: {
          ...data.networkModel,
          follow: false,
          followStatus: null,
          followersCounter:
            data.network?.followersCounter > 0
              ? Number(data.network?.followersCounter) - 1
              : data.network?.followersCounter,
        },
      });
    } else if (userData) {
      replace({
        network: {
          ...userData.network,
          follow: false,
          followStatus: null,
          followersCounter:
            userData.network?.followersCounter > 0
              ? Number(userData.network?.followersCounter) - 1
              : userData.network?.followersCounter,
        },
      });
    }
  };

  const onClickHandler = (e: any) => {
    preventClickHandler(e);

    if (isFunction(onUnfollowHandler)) {
      onUnfollowHandler(e);
    } else {
      if (isFunction(onClick)) {
        onClick(e);
      }

      unfollowHandler(object as UnfollowCallProps, {
        onSuccess: callbacksExecutor(onSuccess, replaceUserData),
      });
    }
  };

  return (
    <Button
      isLoading={isLoading || isLoadingUnfollow}
      onClick={onClickHandler}
      schema={schema}
      leftType={leftType || 'fas'}
      leftIcon="check-circle"
      label={t('following_cap')}
      className={className}
      labelFont="700"
      leftColor={leftColor}
      labelColor={labelColor}
    />
  );
};

export default FollowingButton;
