import classes from './NoticeBox.Item.module.scss';

import React, { Fragment } from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import ListItem from '@lobox/uikit/ListItem';
import Avatar from '@lobox/uikit/Avatar';
import Link from '@lobox/uikit/Link';
import Flex from '@lobox/uikit/Flex';
import ObjectLink from '@shared/components/molecules/ObjectLink';
import type { LinkProps } from '@lobox/uikit/Link';
import type { AvatarProps } from '@lobox/uikit/Avatar';

export type NoticeBoxItemProps = {
  wrapperClassName?: string;
  hover?: boolean;
  isActive?: boolean;
  data: {
    username?: string;
    image?: string;
    title?: string;
    subTitle?: string;
    onClickAvatar?: (arg: any) => void;
    objectId?: string;
  };
  realData?: any;
  avatarProps?: AvatarProps;
  onItemClicked?: (item: any) => void;
  actions?: React.ReactNode;
  to?: LinkProps<any>['to'];
  lefSvg?: (arg: any) => React.ReactNode;
};

const NoticeBoxItem: React.FC<NoticeBoxItemProps> = ({
  wrapperClassName,
  isActive,
  data,
  onItemClicked,
  actions,
  avatarProps,
  realData,
  to,
  lefSvg,
  ...rest
}) => {
  const { image, title, subTitle, onClickAvatar, objectId, username } = data;
  const Wrapper = to ? Link : Flex;
  const wrapperProps: any = to ? { to } : {};
  const ListItemWrapper = objectId ? ObjectLink : Fragment;
  const listItemProps = objectId ? { username, objectId } : {};

  return (
    <Wrapper
      {...wrapperProps}
      className={cnj(
        classes.noticeBoxItemWrapper,
        isActive && classes.activeWrapper,
        wrapperClassName
      )}
    >
      <ListItemWrapper {...listItemProps}>
        <ListItem
          className={classes.listItem}
          leftSvg={
            lefSvg?.(realData || data) || (
              <Avatar
                {...avatarProps}
                onClick={onClickAvatar}
                imgSrc={image}
                size="sm"
              />
            )
          }
          label={title}
          labelColor="thirdText"
          labelSize={16}
          labelFont="bold"
          labelClassName={classes.label}
          secondaryLabel={subTitle}
          secondaryLabelColor="fifthText"
          secondaryLabelSize={15}
          secondaryLabelClassName={classes.secondaryLabel}
          {...rest}
          labelsContainerClassName={classes.labelsContainer}
          onClick={() => onItemClicked?.(realData || data)}
        />
      </ListItemWrapper>
      {actions}
    </Wrapper>
  );
};

export default NoticeBoxItem;
