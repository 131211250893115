import React from 'react';
import Avatar from '../Avatar';
import type { TypographyProps } from '../Typography';
import Typography from '../Typography';
import cnj from '../utils/cnj';
import type { AvatarProps } from '../Avatar/Avatar.component';
import BaseButton from '../Button/BaseButton';
import ObjectLink from '../Link/ObjectLink';
import Flex from '../Flex';
import classes from './index.module.scss';

type TextProps = Omit<TypographyProps, 'children'>;

export interface AvatarCardProps {
  data?: {
    image?: string;
    title?: string;
    subTitle?: string;
    titleHelper?: string;
    id?: string;
    username?: string;
  };
  avatarProps?: AvatarProps;
  titleProps?: TextProps;
  subTitleProps?: TextProps;
  titleHelperProps?: TextProps;
  action?: React.ReactNode;
  onClick?: () => void;
  containerProps?: any;
  noHover?: boolean;
  helperDividerClassName?: string;
  infoWrapClassName?: string;
}

const AvatarCard = ({
  data,
  avatarProps = {},
  titleProps = {},
  subTitleProps = {},
  titleHelperProps = {},
  action,
  onClick,
  containerProps = {},
  noHover,
  helperDividerClassName,
  infoWrapClassName,
}: AvatarCardProps): JSX.Element => {
  const { image, title, titleHelper, subTitle, id, username } = data || {};

  const Wrapper = id ? ObjectLink : BaseButton;
  const wrapperProps = id ? { username: username, objectId: id } : { onClick };

  return (
    <Wrapper
      {...containerProps}
      {...wrapperProps}
      className={cnj(
        classes.itemContainer,
        noHover && classes.itemContainerNoHover,
        containerProps?.className
      )}
    >
      <Avatar
        size="md"
        imgSrc={image}
        {...avatarProps}
        className={cnj(classes.avatar, avatarProps?.className)}
      />
      <Flex className={cnj(classes.infoWrap, infoWrapClassName)}>
        <Flex className={classes.nameWrap}>
          <Typography color="thirdText" font="bold" size={16} {...titleProps}>
            {title}
          </Typography>
          {!!titleHelper && (
            <>
              <Flex className={cnj(classes.divider, helperDividerClassName)} />
              <Typography
                color="primaryDisabledText"
                size={14}
                height={21}
                {...titleHelperProps}
              >
                {titleHelper}
              </Typography>
            </>
          )}
        </Flex>
        <Typography
          mt={2}
          color="primaryDisabledText"
          size={12}
          height={14}
          {...subTitleProps}
        >
          {subTitle}
        </Typography>
      </Flex>
      {action}
    </Wrapper>
  );
};

export default AvatarCard;
