import classes from './NoticeBoxWithoutAction.module.scss';

import React from 'react';
import Flex from '@lobox/uikit/Flex';
import NoticeBox from './NoticeBox';
import NoticeBoxItem from './NoticeBox.Item';
import type { AvatarProps } from '@lobox/uikit/Avatar';
import type ListItemProps from '@lobox/uikit/ListItemProps';

interface NoticeBoxWithoutActionProps {
  layoutProps: {
    className?: string;
    title: string;
    onMoreClicked?: () => void;
    isVisibleShowMore?: boolean;
  };
  data: any[];
  dataKeys: {
    imageKeyName?: string;
    titleKeyName?: string;
    subTitleKeyName?: string;
  };
  belowRender?: (...args: any[]) => React.ReactNode;
  listItemProps?: ListItemProps & {
    avatarProps?: AvatarProps;
    onItemClicked: (...args: any[]) => void;
  };
  isLoading?: boolean;
}

const NoticeBoxWithoutAction = ({
  data,
  layoutProps,
  listItemProps,
  dataKeys,
  belowRender,
  isLoading,
}: NoticeBoxWithoutActionProps): JSX.Element => {
  return (
    <NoticeBox
      className={layoutProps?.className}
      title={layoutProps?.title}
      onMoreClicked={layoutProps?.onMoreClicked}
      isVisibleShowMore={layoutProps?.isVisibleShowMore}
      isLoading={isLoading}
    >
      <Flex className={classes.listContainer}>
        {data?.map((item) => (
          <NoticeBoxItem
            key={item.id}
            className={classes.listItem}
            isActive={item.isActive}
            data={{
              // @ts-ignore
              image: item?.[dataKeys?.imageKeyName],
              // @ts-ignore
              title: item?.[dataKeys?.titleKeyName],
              // @ts-ignore
              subTitle: item?.[dataKeys?.subTitleKeyName],
            }}
            realData={item}
            hover
            actions={belowRender?.(item)}
            {...listItemProps}
          />
        ))}
      </Flex>
    </NoticeBox>
  );
};

export default NoticeBoxWithoutAction;
