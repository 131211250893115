export const messagesVariations = {
  JOB: 'JOB',
  TEXT: 'TEXT',
  POST: 'POST',
  FILE: 'FILE',
  TASK: 'TASK',
  IMAGE: 'IMAGE',
  PROFILE: 'PROFILE',
  MEETING: 'MEETING',
  BIRTHDAY: 'BIRTHDAY',
  AVAILABILITY: 'AVAILABILITY',
  RECOMMENDATION: 'RECOMMENDATION',
  IS_CREATED_ROOM_MESSAGE: 'IS_CREATED_ROOM_MESSAGE',
} as const;

export const messageTypes = {
  MESSAGE_TYPE_ERROR: -1,
  MESSAGE_TYPE_CONNECTION_REQUEST: 0,
  MESSAGE_TYPE_CONNECTION_REPLY: 1,
  MESSAGE_TYPE_PING: 2,
  MESSAGE_TYPE_PONG: 3,
  MESSAGE_TYPE_TEXT: 4,
  MESSAGE_TYPE_ACK: 5,
  MESSAGE_TYPE_DELIVERY_RECEIPT: 6,
  MESSAGE_TYPE_SEEN: 7,
  MESSAGE_TYPE_DELETE_TEXT: 8,
  MESSAGE_TYPE_EDIT_TEXT: 9,
  MESSAGE_TYPE_REPLY_TEXT: 10,
  MESSAGE_TYPE_FORWARD_TEXT: 11,
  MESSAGE_TYPE_CLEAR_HISTORY_TEXT: 12,
  MESSAGE_TYPE_NOTIFICATION: 13,
  MESSAGE_LAST_ACTIVITY_REQUEST: 14,
  MESSAGE_LAST_ACTIVITY_REPLY: 15,
  MESSAGE_ACK_RECEIPT: 16,
  MESSAGE_START_TYPING: 17,
  MESSAGE_STOP_TYPING: 18,
} as const;

export const MessageInputActions = {
  SEND_MESSAGE: 'SEND_MESSAGE',
  REPLAY_MESSAGE: 'REPLAY_MESSAGE',
  EDIT_MESSAGE: 'EDIT_MESSAGE',
  FORWARD_MESSAGE: 'FORWARD_MESSAGE',
} as const;

export const messageFileTypes = {
  Image: 0,
  Video: 1,
  Audio_Music: 2,
  Voice_Message: 3,
  Video_Message: 4,
  Location: 5,
  Document_Files: 6,
  Contact: 7,
} as const;

export const roomMemberRoles = {
  Admin: 1,
  Owner: 0,
  Member: 2,
};

export const sectionNames = {
  Conversation: 'Conversation',
  StaredMessages: 'StaredMessages',
  SearchResult: 'SearchResult',
} as const;

export const messageSendingStatus = {
  SENT: 'SENT',
  SENDING: 'SENDING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  META_SUCCESS: 'META_SUCCESS',
  META_ERROR: 'META_ERROR',
  META_UPLOADING: 'META_UPLOADING',
} as const;

export const chatListItemVariant = {
  MESSAGE_LIST: 'MESSAGE_LIST',
  BLOCK: 'BLOCK',
  ARCHIVE: 'ARCHIVE',
} as const;
export const SecondsInDay = 60 * 60 * 24;
export const OnlineDuration = 60 * 5;
