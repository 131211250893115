import React from 'react';
import type { colorsKeys } from '../utils/makeStyle';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import Icon from '../Icon';
import { makeDynamicStyles } from '../utils/makeDynamicStyles';
import classes from './HeroIcon.module.scss';

type KeyType =
  | 'irrelevant'
  | 'green'
  | 'orange'
  | 'blue'
  | 'gray'
  | 'themedGray'
  | 'brand'
  | 'hover'
  | 'brandWhite'
  | 'white10'
  | 'coal'
  | 'transparent'
  | 'techGray_20'
  | 'disabledGray_graphene';

const backgroundList: Record<KeyType, colorsKeys> = {
  irrelevant: 'irrelevant_10',
  green: 'mint_10',
  orange: 'pendingOrange_10',
  blue: 'navyBlue_10',
  gray: 'gray_10',
  themedGray: 'hover75_hoverGray15',
  brand: 'brand_10',
  coal: 'hover75_hoverGray15',
  hover: 'backgroundIconSecondary',
  brandWhite: 'brand',
  white10: 'white_10',
  transparent: 'transparent',
  techGray_20: 'backgroundIconSecondary',
  disabledGray_graphene: 'backgroundIconSecondary',
};

const foregroundList: Record<KeyType, colorsKeys> = {
  irrelevant: 'irrelevant',
  green: 'mint',
  orange: 'pendingOrange',
  blue: 'navyBlue',
  gray: 'gray',
  themedGray: 'primaryText',
  brand: 'brand',
  coal: 'thirdText',
  hover: 'hover',
  brandWhite: 'white',
  white10: 'white',
  transparent: 'graphene',
  techGray_20: 'techGray_20',
  disabledGray_graphene: 'disabledGray_graphene',
};

export type HeroIconColorTypes = keyof typeof backgroundList;

export interface HeroIconProps {
  variant?: 'circle' | 'square';
  className?: string;
  iconClassName?: string;
  iconName?: string;
  color: HeroIconColorTypes;
  size?: number;
  iconSize?: number;
  iconType?: 'fas' | 'far' | 'fal';
}

const HeroIcon: React.FC<HeroIconProps> = ({
  className,
  iconName,
  color,
  size,
  iconSize = 26,
  iconType = 'far',
  variant = 'circle',
  children,
}) => {
  return (
    <>
      <Flex
        className={cnj(
          classes.heroIconRoot,
          classes[`backgroundColor-${backgroundList?.[color]}`],
          variant === 'square' && classes.square,
          className
        )}
        {...makeDynamicStyles({
          width: size,
          height: size,
          minWidth: size,
          minHeight: size,
        })}
      >
        {children || (
          <Icon
            type={iconType}
            name={iconName}
            size={iconSize}
            color={foregroundList[color]}
          />
        )}
      </Flex>
    </>
  );
};

export default HeroIcon;
