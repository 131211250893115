import React from 'react';
import Button from '@lobox/uikit/Button';
import useTheme from '@lobox/uikit/utils/useTheme';
import { preventClickHandler, useTranslation } from '@lobox/utils';
import useUnfollowHashtag from '@shared/hooks/api-hook/useUnfollowHashtag';
import isFunction from 'lodash/isFunction';
import type ButtonSchemaType from '@lobox/uikit/ButtonSchemaType';
import type { ButtonProps } from '@lobox/uikit/Button';

interface CommonProps {
  className?: string;
  schema?: ButtonSchemaType;
  onClick?: ButtonProps['onClick'];
}
interface ObjectProps extends CommonProps {
  onSuccess?: (args: any) => void;
  hashtag: {
    id: string;
  };
  onUnfollowHandler: (args: any) => void;
}
interface UnfollowHandlerProps extends CommonProps {
  onUnfollowHandler: (args: any) => void;
  onSuccess?: (args: any) => void;
  hashtag: {
    id: string;
    name: string;
  };
}

const FollowingHashtagButton = ({
  className,
  schema,
  onClick,
  onSuccess,
  hashtag,
  onUnfollowHandler,
}: Partial<ObjectProps | UnfollowHandlerProps>): JSX.Element => {
  const { t } = useTranslation();
  const { isDark } = useTheme();
  const { unfollowCall, isLoading } = useUnfollowHashtag();

  const onClickHandler = (e: any) => {
    preventClickHandler(e);

    if (isFunction(onUnfollowHandler)) {
      onUnfollowHandler(e);
    } else {
      if (isFunction(onClick)) {
        onClick(e);
      }

      unfollowCall(hashtag.id, {
        onSuccess,
      });
    }
  };

  return (
    <Button
      isLoading={isLoading}
      onClick={onClickHandler}
      schema={schema || (isDark ? 'black' : 'ghost')}
      leftType="fas"
      leftIcon="check-circle"
      label={t('following_cap')}
      className={className}
      labelFont="700"
    />
  );
};

export default FollowingHashtagButton;
