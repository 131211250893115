import React from 'react';
import ErrorBoundaryToast from '@lobox/uikit/ErrorBoundary/ErrorBoundaryToast';
import FormModalComponent from './FormModal.component';
import type { FormModalProps } from './FormModal.component';

const FormModal = (props: FormModalProps): JSX.Element => (
  <ErrorBoundaryToast>
    <FormModalComponent {...props} />
  </ErrorBoundaryToast>
);

export default FormModal;
