import classes from './index.module.scss';

import React from 'react';
import partial from 'lodash/partial';
import BaseButton from '../Button/BaseButton';
import cnj from '../utils/cnj';
import Typography from '../Typography';
import IconButton from '../Button/IconButton';
import type { IconButtonProps } from '../Button/IconButton';
import type { TypographyProps } from '../Typography';

interface ITagProps {
  realData?: any;
  onClick?: (item: any) => void;
  onDelete?: (item: any) => void;
  visibleIcon?: boolean;
  className?: string;
  selected?: boolean;
  label: string;
  labelProps?: Partial<TypographyProps>;
  iconButtonProps?: IconButtonProps;
  disabled?: boolean;
  disabledHover?: boolean;
  variant?: 'badge' | 'normal';
}

const Tag = ({
  realData,
  onDelete,
  onClick,
  visibleIcon = true,
  className,
  selected,
  label,
  labelProps,
  iconButtonProps,
  disabled,
  disabledHover,
  variant,
}: ITagProps): JSX.Element => {
  return (
    <BaseButton
      className={cnj(
        classes.tagContainer,
        selected && classes.selected,
        variant === 'badge' && classes.tagContainerBadge,
        disabledHover && classes.disabledHover,
        className
      )}
      onClick={onClick ? partial(onClick, realData) : undefined}
      disabled={disabled}
    >
      <Typography
        font="700"
        size={15}
        {...labelProps}
        className={cnj(
          classes.tag_inner,
          variant === 'badge' && classes.variant === 'badge',
          labelProps?.className
        )}
      >
        {label}
      </Typography>
      {visibleIcon && (
        <IconButton
          onClick={onDelete ? partial(onDelete, realData) : undefined}
          name="times"
          type="fas"
          size="sm"
          className={classes.close}
          colorSchema="tag"
          {...iconButtonProps}
        />
      )}
    </BaseButton>
  );
};

export default Tag;
