import classes from './ObjectCard.block.module.scss';

import React, { useEffect, useRef } from 'react';
import Button from '@lobox/uikit/Button';
import useTheme from '@lobox/uikit/utils/useTheme';
import cnj from '@lobox/uikit/utils/cnj';
import { preventClickHandler, useTranslation } from '@lobox/utils';
import { BLOCK_TIMEOUT } from '@shared/constants/enums';
import useBlock from '@shared/hooks/api-hook/useBlock';
import isFunction from 'lodash/isFunction';
import type { EntityType } from '@lobox/utils';

export interface ObjectCardBlock {
  id: string;
  className?: string;
  entityType: EntityType;
  undoBlock?: (...args: any[]) => any;
  remove?: (...args: any[]) => any;
}

const ObjectCardBlock: React.FC<ObjectCardBlock> = ({
  id,
  className,
  entityType,
  undoBlock,
  remove,
}) => {
  const undoClick = useRef(false);
  const { isDark } = useTheme();
  const { t } = useTranslation();
  const { block } = useBlock();

  useEffect(() => {
    const blockTimeout = setTimeout(() => {
      if (isFunction(remove)) {
        remove();
      }
    }, BLOCK_TIMEOUT);

    const handleBlock = () => {
      clearTimeout(blockTimeout);

      if (!undoClick.current) {
        block({ entityId: id, entityType });
      }
    };

    window.onbeforeunload = handleBlock;
    return handleBlock;
  }, []);

  return (
    <Button
      schema={isDark ? 'secondary-dark' : 'semi-transparent'}
      rightIcon="undo"
      rightType="fas"
      label={t('unblock')}
      className={cnj(classes.undoButton, className)}
      onClick={(e) => {
        preventClickHandler(e);
        undoClick.current = true;
        if (undoBlock()) {
          undoBlock();
        }
      }}
    />
  );
};

export default ObjectCardBlock;
