import classes from './NoticeBox.skeleton.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Box from '@lobox/uikit/Layout/Box';
import ItemSkeleton from '@shared/components/molecules/NoticeBox/Item.skeleton';

export interface NoticeBoxSkeletonProps {
  className?: string;
}

const list = ['item_1', 'item_4', 'item_3'];

const NoticeBoxSkeleton: React.FC<NoticeBoxSkeletonProps> = ({ className }) => {
  return (
    <Box className={cnj(classes.noticeBoxSkeletonRoot, className)}>
      {list.map((key) => (
        <ItemSkeleton isPage key={key} />
      ))}
    </Box>
  );
};

export default NoticeBoxSkeleton;
