import React from 'react';
import Button from '@lobox/uikit/Button';
import useTheme from '@lobox/uikit/utils/useTheme';
import { preventClickHandler, useTranslation } from '@lobox/utils';
import useFollowHashtag from '@shared/hooks/api-hook/useFollowHashtag';
import isFunction from 'lodash/isFunction';
import type ButtonSchemaType from '@lobox/uikit/ButtonSchemaType';
import type { ButtonProps } from '@lobox/uikit/Button';

interface FollowProps {
  className?: string;
  schema?: ButtonSchemaType;
  onClick?: ButtonProps['onClick'];
  onSuccess?: (args: any) => void;
  onFollowHandler?: (args: any) => void;
  hashtag?: {
    id: string;
  };
}

const FollowHashtagButton = ({
  schema,
  className,
  onClick,
  onSuccess,
  onFollowHandler,
  hashtag,
}: FollowProps): JSX.Element => {
  const { t } = useTranslation();
  const { isDark } = useTheme();
  const { followCall, isLoading } = useFollowHashtag();

  const onClickHandler = (e: any) => {
    preventClickHandler(e);

    if (isFunction(onFollowHandler)) {
      onFollowHandler(e);
    } else {
      if (isFunction(onClick)) {
        onClick(e);
      }

      followCall(hashtag?.id as string, {
        onSuccess,
      });
    }
  };

  return (
    <Button
      isLoading={isLoading}
      className={className}
      fullWidth
      schema={schema || (isDark ? 'secondary-dark' : 'semi-transparent')}
      leftIcon="plus"
      leftType="fas"
      label={t('follow_cap')}
      onClick={onClickHandler}
    />
  );
};

export default FollowHashtagButton;
