import classes from './ModalBodyBase.component.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import type FlexProps from '@lobox/uikit/FlexProps';

export interface ModalBodyBaseProps extends FlexProps {
  className?: string;
  children?: React.ReactNode;
}

const ModalBodyBase = (
  { className, children, ...rest }: ModalBodyBaseProps,
  ref: any
) => {
  return (
    <Flex className={cnj(classes.modalBody, className)} ref={ref} {...rest}>
      {children}
    </Flex>
  );
};

export default React.forwardRef(ModalBodyBase);
