import classes from './NetworkItem.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import ObjectLink from '@shared/components/molecules/ObjectLink';

interface NetworkItemProps {
  onClickAvatar?: () => void;
  item: any;
  action?: any;
  withHover?: boolean;
  wrapperClassName?: string;
}

const NetworkItem = ({
  onClickAvatar,
  item,
  action,
  withHover,
  wrapperClassName,
}: NetworkItemProps): JSX.Element => {
  return (
    <ObjectLink username={item.username} objectId={item.objectId} className={wrapperClassName}>
      <AvatarCard
        noHover
        infoWrapClassName={classes.infoWrapNetworkModified}
        onClick={onClickAvatar}
        key={item.id}
        data={{
          image: item.croppedImageUrl,
          title: item.fullName,
          subTitle: item.subTitle || item.usernameAtSign,
        }}
        containerProps={{
          className: cnj(classes.itemWrap, withHover && classes.withHover),
        }}
        titleProps={{ height: 20, size: 16, font: '700', isTruncated: true }}
        subTitleProps={{
          height: 18,
          mt: 4,
          size: 14,
          color: 'border',
          isTruncated: true,
        }}
        action={<Flex className={classes.actions}>{action}</Flex>}
        avatarProps={{
          isCompany: item.isPage,
          onClick: onClickAvatar,
          className: classes.avatar,
        }}
      />
    </ObjectLink>
  );
};

export default NetworkItem;
