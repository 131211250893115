import classes from './SearchResultItem.ListItem.module.scss';

import React from 'react';
import Icon from '@lobox/uikit/Icon';
import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';

interface ListItemProps {
  className?: string;
  iconName: string;
  label: string | number;
}

const ListItem: React.FC<ListItemProps> = ({ className, iconName, label }) => {
  return (
    <Flex className={cnj(classes.listItemRoot, className)}>
      <Icon
        type="far"
        name={iconName}
        size={14}
        color="mint"
        className={classes.icon}
      />
      <Typography
        font="400"
        size={15}
        height={21}
        color="gray"
        className={classes.label}
      >
        {label}
      </Typography>
    </Flex>
  );
};

export default ListItem;
