import classes from './FollowRequestItem.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import {
  preventClickHandler,
  QueryKeys,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import useAcceptFollow from '@shared/hooks/api-hook/useAcceptFollow';
import useDeclineFollow from '@shared/hooks/api-hook/useDeclineFollow';
import callbacksExecutor from '@shared/utils/callbacksExecutor';
import { useQueryClient } from '@tanstack/react-query';
import { followStatus } from '@shared/constants/enums';

export interface FollowRequestItemProps {
  id?: string;
  username: string;
  className?: string;
  onSuccessAccept?: (data: any, variables?: void, context?: unknown) => void;
  onSuccessDecline?: (data: any, variables?: void, context?: unknown) => void;
}

const FollowRequestItem = ({
  id,
  className,
  username,
  onSuccessAccept,
  onSuccessDecline,
}: FollowRequestItemProps): JSX.Element => {
  const { t } = useTranslation();

  const { acceptCall, isLoading: isLoadingAccept } = useAcceptFollow();
  const { declineCall, isLoading: isLoadingDecline } = useDeclineFollow();

  const { replace } = useUpdateQueryData([QueryKeys.objectDetail, username]);
  const queryClient = useQueryClient();
  const userData = queryClient.getQueryData([QueryKeys.objectDetail, username]);
  const replaceDeclineUserData = () => {
    if (userData) {
      replace({
        network: {
          ...userData.network,
          back: false,
          backStatus: null,
        },
      });
    }
  };

  const replaceAcceptUserData = (res: any) => {
    if (userData) {
      replace({
        network: {
          ...userData.network,
          back: true,
          backStatus: followStatus.ACCEPTED,
        },
      });
    }
  };

  const handleAccept = (e: any) => {
    preventClickHandler(e);
    acceptCall(id, {
      onSuccess: callbacksExecutor(onSuccessAccept, replaceAcceptUserData),
    });
  };

  const handleDecline = (e: any) => {
    preventClickHandler(e);
    declineCall(id, {
      onSuccess: callbacksExecutor(onSuccessDecline, replaceDeclineUserData),
    });
  };

  return (
    <Flex className={cnj(classes.followRequestItemRoot, className)}>
      <Button
        isLoading={isLoadingDecline}
        schema="semi-transparent"
        label={t('reject')}
        leftIcon="times"
        className={classes.button}
        onClick={handleDecline}
      />
      <Button
        isLoading={isLoadingAccept}
        schema="primary-blue"
        leftIcon="check"
        label={t('confirm')}
        className={classes.button}
        onClick={handleAccept}
      />
    </Flex>
  );
};

export default FollowRequestItem;
