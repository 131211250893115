import classes from './CardItem.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Avatar from '@lobox/uikit/Avatar';
import Typography from '@lobox/uikit/Typography';
import Flex from '@lobox/uikit/Flex';
import Link from '@lobox/uikit/Link';
import type AvatarSizeType from '@lobox/uikit/AvatarSizeType';
import type { LinkProps } from '@lobox/utils';

interface CardItemProps {
  className?: string;
  image: any;
  title: string;
  subtitle: string;
  description: string;
  avatarSize?: AvatarSizeType;
  to?: LinkProps<any>['to'];
}

const CardItem: React.FC<CardItemProps> = ({
  className,
  image,
  title,
  subtitle,
  description,
  avatarSize = 'slg',
  to,
}) => {
  const Wrapper = to ? Link : Flex;
  const wrapperProps: any = to ? { to } : {};

  return (
    <Wrapper {...wrapperProps} className={cnj(classes.cardItemRoot, className)}>
      <Avatar isCompany imgSrc={image} size={avatarSize} />
      <Flex className={classes.textWrapper}>
        <Typography font="700" size={15} height={17.58} color="thirdText">
          {title}
        </Typography>
        <Typography
          font="400"
          size={15}
          height={21}
          color="disabledGray_highlightIcon"
        >
          {subtitle}
        </Typography>
        <Typography
          font="400"
          size={15}
          height={21}
          mt="auto"
          color="fifthText"
        >
          {description}
        </Typography>
      </Flex>
    </Wrapper>
  );
};

export default CardItem;
