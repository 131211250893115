import ModalDialog from './BasicModal/Modal.Dialog';
import ModalBody from './BasicModal/Bodies/Primary';
import ModalFooter from './BasicModal/ModalFooter';
import ModalBodyImage from './BasicModal/components/ModalBodyImage';
import ModalForm from './FormModal';
import FixedRightSideModalDialog from './FixedRightSideModalDialog';
import {
  ModalHeaderSimple,
  ModalHeaderWithTab,
  ModalHeaderWithAction,
} from './BasicModal/Headers';

export {
  ModalHeaderSimple,
  ModalHeaderWithTab,
  ModalHeaderWithAction,
  FixedRightSideModalDialog,
};
export { ModalForm, ModalDialog, ModalBodyImage, ModalBody, ModalFooter };
