import classes from './NoticeBox.module.scss';

import React from 'react';
import cnj from '@lobox/uikit/utils/cnj';
import Divider from '@lobox/uikit/Divider';
import Flex from '@lobox/uikit/Flex';
import Paper from '@lobox/uikit/Paper';
import Typography from '@lobox/uikit/Typography';
import { useTranslation } from '@lobox/utils';
import NoticeBoxSkeleton from '@shared/components/molecules/NoticeBox/NoticeBox.skeleton';

interface NoticeBoxProps {
  className?: string;
  title: string;
  isVisibleShowMore?: boolean;
  onMoreClicked?: () => void;
  footerActionText?: string;
  isLoading?: boolean;
}

const NoticeBox: React.FC<NoticeBoxProps> = ({
  className,
  title,
  isVisibleShowMore = true,
  onMoreClicked,
  footerActionText,
  children,
  isLoading,
}) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <NoticeBoxSkeleton className={classes.skeletonWrapper} />;
  }

  return (
    <Paper
      title={title}
      className={cnj(classes.paperContainer, className)}
      contentClassName={classes.paperContentContainer}
      noHover
      titleContainerClassName={classes.titleContainer}
      titleClassName={classes.title}
    >
      <Divider className={classes.divider} />
      {children}
      {isVisibleShowMore && (
        <Flex className={classes.moreContainer}>
          <Typography
            color="primaryText"
            height={21}
            size={14}
            onClick={onMoreClicked}
          >
            {footerActionText || t('see_all')}
          </Typography>
        </Flex>
      )}
    </Paper>
  );
};

export default NoticeBox;
