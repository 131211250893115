import React from 'react';
import Form from '@lobox/uikit/Form';
import ModalHeaderSimple from '../BasicModal/Headers/Simple';
import ModalBodyImage from '../BasicModal/components/ModalBodyImage';
import ModalDialog from '../BasicModal/Modal.Dialog';
import ModalBody from '../BasicModal/Bodies/Primary';
import type { ModalHeaderSimpleProps } from '../BasicModal/Headers/Simple';
import type { ModalBodyImageProps } from '../BasicModal/components/ModalBodyImage';
import type { ModalDialogProps } from '../BasicModal/Modal.Dialog';
import type { ModalBodyProps } from '../BasicModal/Bodies/Primary';

export interface FormModalProps {
  dialogProps: ModalDialogProps;
  headerProps: ModalHeaderSimpleProps;
  bodyProps: ModalBodyProps;
  bodyImageProps: ModalBodyImageProps;
  formProps: any;
}

const FormModal = ({
  dialogProps,
  headerProps,
  bodyProps,
  bodyImageProps,
  formProps = {},
}: FormModalProps): JSX.Element => (
  <Form {...formProps}>
    {() => (
      <ModalDialog {...dialogProps}>
        <ModalHeaderSimple {...headerProps} />
        <ModalBody {...bodyProps}>
          <ModalBodyImage {...bodyImageProps} />
        </ModalBody>
      </ModalDialog>
    )}
  </Form>
);

export default FormModal;
