import React from 'react';
import useConfirm from '@lobox/uikit/Confirmation/useConfirm';
import isFunction from 'lodash/isFunction';
import { useTranslation } from '@lobox/utils';

const modelContext = React.createContext({});

const ModalProviderComponent: React.FC<any> = ({
  children,
  onClose,
  onBack,
  showConfirm,
}) => {
  const { t } = useTranslation();

  const { openConfirm, Confirm } = useConfirm({
    cancelCallback: onConfirmCloseHandler,
    title: t('confirm_title'),
    isReverse: true,
    message: t('confirm_desc'),
    cancelButtonText: t('confirm_cancel'),
    confirmButtonText: t('confirm_ok'),
  });
  function onConfirmCloseHandler() {
    if (isFunction(onClose)) {
      onClose(null);
    } else if (isFunction(onBack)) {
      onBack(null);
    }
  }

  function handleCloseModal() {
    if (showConfirm) {
      openConfirm();
    } else {
      onClose();
    }
  }
  return (
    <modelContext.Provider value={{ handleCloseModal, onBack }}>
      <Confirm />
      {children}
    </modelContext.Provider>
  );
};

export const useModalContext = (): any => React.useContext(modelContext);
export default ModalProviderComponent;
