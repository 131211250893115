import classes from './FollowersAndFollowings.component.module.scss';

import React, { Fragment } from 'react';
import BaseButton from '@lobox/uikit/Button/BaseButton';
import cnj from '@lobox/uikit/utils/cnj';
import DividerVertical from '@lobox/uikit/Divider/DividerVertical';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import { pluralize, useGlobalDispatch, useTranslation } from '@lobox/utils';
import { SocialConnectionsTabs } from '@shared/constants/enums';
import type { NetworkModalObjectDataType } from '@shared/types';

export interface StylesProps {
  followStyle?: string;
}

interface FollowersAndFollowingsProps {
  className?: string;
  onClick?: () => void;
  networkModalObject: NetworkModalObjectDataType;
  disabled?: boolean;
  styles?: StylesProps;
  dividerProps?: {
    [key: string]: any;
  };
  onClickPosts?: () => void;
  variant?: 'row' | 'col';
}

const FollowersAndFollowings: React.FC<FollowersAndFollowingsProps> = ({
  className,
  disabled,
  onClick: onClickHandler,
  networkModalObject,
  styles = {},
  dividerProps = {},
  onClickPosts,
  variant = 'row',
}) => {
  const { t } = useTranslation();
  const dispatch = useGlobalDispatch();
  const isColVariant = variant === 'col';

  const openObjectNetworkModal = (currentTab: string) => {
    dispatch({
      type: 'OPEN_OBJECT_NETWORK_MODAL',
      payload: { currentTab, ...(networkModalObject || {}) },
    });
    onClickHandler?.();
  };

  const menuItems = [
    {
      label: pluralize(
        t('follower_cap'),
        Number(networkModalObject?.followers),
        false
      ),
      value: networkModalObject?.followers,
      onClick: () => openObjectNetworkModal(SocialConnectionsTabs.followers),
    },
    {
      label: t('following_cap'),
      value: networkModalObject?.following,
      onClick: () => openObjectNetworkModal(SocialConnectionsTabs.following),
    },
    onClickPosts && {
      label: t('posts'),
      value: networkModalObject?.postsCounter,
      onClick: onClickPosts,
    },
  ].filter(Boolean);

  return (
    <Flex className={cnj(className, classes.followContainer)}>
      {menuItems.map(({ label, value, onClick }, index) => (
        <Fragment key={label}>
          <BaseButton
            onClick={onClick}
            className={cnj(
              classes.followInfo,
              isColVariant && classes.followInfoCol,
              disabled && classes.followInfoDisabled,
              styles.followStyle
            )}
            disabled={disabled}
          >
            <Typography
              className={cnj(
                'value',
                classes.followers,
                isColVariant && classes.followersCol
              )}
              font="500"
              size={isColVariant ? 17 : 16}
              height={isColVariant ? 12 : 22}
              color={disabled ? 'primaryDisabledText' : 'primaryText'}
            >
              {value || 0}
            </Typography>
            <Typography
              color={disabled ? 'primaryDisabledText' : 'border'}
              font="400"
              size={isColVariant ? 13 : 16}
              height={isColVariant ? 10 : 22}
            >
              {label}
            </Typography>
          </BaseButton>
          {index !== menuItems?.length - 1 && (
            <DividerVertical
              height={isColVariant ? 37 : 16}
              className={classes.divider}
              {...dividerProps}
            />
          )}
        </Fragment>
      ))}
    </Flex>
  );
};

export default FollowersAndFollowings;
