import { urlsBuilder } from '@lobox/utils';
import appEnvironment from './env';

const { baseUrl, apiBaseUrl, storageBaseUrl, wsUrl } = appEnvironment;
const commonUrls = urlsBuilder({ baseUrl, apiBaseUrl, storageBaseUrl });

const urls = {
  base: `${baseUrl}`,
  baseApi: `${apiBaseUrl}`,
  storageBaseUrl: `${storageBaseUrl}`,
  login: commonUrls.login,
  languagesAddress: commonUrls.languagesAddress,
  signUp: commonUrls.signUp,
  getName: commonUrls.getName,
  getCode: commonUrls.getCode,
  forgetPassword: commonUrls.forgetPassword,
  helpAndSupport: commonUrls.helpAndSupport,
  business: appEnvironment.businessAppBaseUrl,
  placeholderDark: commonUrls.placeholder.dark,
  placeholderLight: commonUrls.placeholder.light,
  placeholderPage: commonUrls.placeholder.page,
  placeholderUser: commonUrls.placeholder.user,
  socialAuth: commonUrls.socialAuth,
  wsUrl: `${wsUrl}`,
};

export default urls;
