import classes from './ObjectCard.component.module.scss';

import React, { useMemo, useState } from 'react';
import Avatar from '@lobox/uikit/Avatar';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Icon from '@lobox/uikit/Icon';
import IconButton from '@lobox/uikit/Button/IconButton';
import Image from '@lobox/uikit/Image';
import Link from '@lobox/uikit/Link';
import OverflowTip from '@lobox/uikit/Typography/OverflowTip';
import PopperItem from '@lobox/uikit/PopperItem';
import PopperMenu from '@lobox/uikit/PopperMenu';
import Spinner from '@lobox/uikit/Spinner';
import Tag from '@lobox/uikit/Tag';
import Typography from '@lobox/uikit/Typography';
import {
  APP_ENTITIES,
  preventClickHandler,
  useGlobalDispatch,
  useTranslation,
} from '@lobox/utils';
import { PageOwnersForbiddenActions } from '@shared/hooks/useCardMenuBuilder';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import useGetPageMembers from '@shared/hooks/api-hook/useGetPageMembers';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import HorizontalInfoBox from '@shared/components/molecules/HorizontalInfoBox';
import { SocialConnectionsTabs } from '@shared/constants/enums';
import urls from '@shared/constants/urls';
import UnBlockButton from './ObjectCard.block';
import type { MoreMenuItemType } from '@shared/hooks/useCardMenuBuilder';
import type { RoleType } from '@lobox/utils';
import type { AvatarProps } from '@lobox/uikit/Avatar';
import type { LinkProps } from '@lobox/utils';
import Skeleton from '@lobox/uikit/Skeleton/Skeleton';

export interface ObjectCardProps {
  image?: any;
  avatarProps?: AvatarProps;
  title?: string;
  croppedHeaderImageLink?: string;
  postsCounter?: string;
  locationTitle?: string;
  subtitle?: string;
  description?: React.ReactNode;
  notification?: React.ReactNode;
  actions?: React.ReactNode;
  to?: LinkProps<any>['to'];
  href?: string;
  onClick?: (...args: any[]) => any;
  moreList?: Array<MoreMenuItemType>;
  id?: string;
  ownerId?: string;
  className?: string;
  followers?: string;
  following?: string;
  pageRole?: RoleType;
  pageRoleIndex?: number;
  isBlocked?: boolean;
  undoBlock?: (...args: any[]) => any;
  remove?: (...args: any[]) => any;
  onClickPosts?: (e: any) => void;
  isPrivateAccount?: boolean;
  isLoading?: boolean;
}

const ObjectCard = ({
  className,
  image,
  croppedHeaderImageLink,
  avatarProps,
  locationTitle,
  postsCounter,
  title,
  subtitle,
  description,
  notification,
  followers,
  following,
  actions,
  to,
  href,
  onClick,
  moreList = [],
  id,
  ownerId,
  pageRole,
  isBlocked,
  undoBlock,
  remove,
  onClickPosts,
  isPrivateAccount,
  isLoading,
}: ObjectCardProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useGlobalDispatch();
  const isPage = !!avatarProps?.isCompany;
  const placeholder = isPage ? urls.placeholderPage : urls.placeholderUser;
  const safeMoreList = useMemo(
    () =>
      moreList.filter(
        (i) => !PageOwnersForbiddenActions.some((fA) => fA === i.key)
      ),
    [moreList?.map((item) => item?.label)?.join('')]
  );
  const [checkOwnerShip, setCheckOwnerShip] = useState(false);
  const { checkIsBusinessOwner, checkIsAuthUser } = useGetAppObject();
  const Wrapper = to ? Link : Flex;
  const wrapperProps: any = to
    ? { to }
    : href
      ? { as: 'a', href }
      : { onClick };

  const { data: pageMembers = [], isLoading: isLoadingPageMembers } =
    useGetPageMembers({
      businessPageId: isBusinessApp ? undefined : id,
      enabled: checkOwnerShip,
    });

  const shouldCall = !isBusinessApp && isPage;

  const isOwner = useMemo(() => checkIsBusinessOwner(id), [id]);
  const isAuth = useMemo(() => checkIsAuthUser(id), [id]);
  const isMember = useMemo(
    () => pageMembers.some((i) => checkIsAuthUser(i.user.id)),
    [pageMembers]
  );
  const isSafeMode =
    (isBusinessApp && !isPage && (isOwner || isAuth)) ||
    (!isBusinessApp && isPage && isMember);

  const openObjectNetworkModal = (e: any, currentTab: string) => {
    preventClickHandler(e);
    dispatch({
      type: 'OPEN_OBJECT_NETWORK_MODAL',
      payload: {
        currentTab,
        objectTitle: title,
        isPage,
        objectUsername: subtitle,
        objectId: id,
        followers,
        following,
        // network,
      },
    });
  };

  return (
    <Wrapper
      {...wrapperProps}
      className={cnj(classes.cardContainer, className)}
    >
      <Flex className={classes.bgImageWrapper}>
        <Image
          defaultTag
          resolution={croppedHeaderImageLink ? 'medium' : 'original'}
          className={classes.bgImage}
          src={croppedHeaderImageLink || placeholder}
        />
        <Flex className={classes.absolute}>
          <Avatar bordered imgSrc={image} size="mlg" {...avatarProps} />
          <Flex className={classes.myPageInfo}>
            {!!pageRole && (
              <Tag
                label={pageRole}
                visibleIcon={false}
                disabledHover
                className={classes.roleTag}
                labelProps={{
                  noWrap: true,
                }}
                variant="badge"
              />
            )}
            <Flex className={classes.actionButtonWrapper}>
              {notification}
              {!moreList?.length ? null : (
                <PopperMenu
                  closeOnScroll
                  placement="bottom-end"
                  buttonComponent={(visible) => (
                    <IconButton
                      className={cnj(
                        classes.actionButton,
                        visible && classes.activeMenuButton
                      )}
                      colorSchema={visible ? 'primary-blue' : 'primary'}
                      type="far"
                      name="ellipsis-h"
                      size="md18"
                      onClick={() => {
                        if (shouldCall) {
                          setCheckOwnerShip(true);
                        }
                      }}
                    />
                  )}
                >
                  {shouldCall && isLoadingPageMembers ? (
                    <Flex className={classes.spinnerWrapper}>
                      <Spinner />
                    </Flex>
                  ) : (
                    (isSafeMode ? safeMoreList : moreList).map(
                      ({ key, iconName, label, onClick: oC }) => (
                        <PopperItem
                          {...{
                            key: key + id,
                            iconName,
                            label,
                            onClick: oC,
                          }}
                        />
                      )
                    )
                  )}
                </PopperMenu>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex className={classes.contentWrapper}>
        {isBlocked && (
          <Flex className={classes.blockedTextWrapper}>
            <Typography size={20} color="thirdText" font="700" height={23}>
              {`${t('u_blocked')} ${title}`}
            </Typography>
            <Typography
              font="400"
              size={12}
              mt={4}
              color="secondaryDisabledText"
              height={14}
            >
              {t('y_w_n_able_acc_rec_msg')}
            </Typography>
          </Flex>
        )}

        {!isBlocked && (
          <>
            <Flex>
              <OverflowTip
                className={classes.objectName}
                size={20}
                color="thirdText"
                font="700"
                height={23}
              >
                {title}
              </OverflowTip>
            </Flex>

            {!!subtitle && (
              <Typography
                font="400"
                size={12}
                mt={4}
                color="secondaryDisabledText"
                height={14}
                isTruncated
              >
                {subtitle}
              </Typography>
            )}
            <Flex className={classes.textItemWrap}>
              <Icon
                color="primaryText"
                name={isPage ? 'list-ul' : 'briefcase-blank'}
                type="far"
                size={14}
              />
              <OverflowTip ml={6} size={14} font="400" height={18} isTruncated>
                {description}
              </OverflowTip>
            </Flex>
            <Flex className={classes.textItemWrap}>
              <Icon
                color={locationTitle ? 'primaryText' : 'muteMidGray_hover_6'}
                name="map-marker-alt"
                type="far"
                size={14}
              />
              <OverflowTip
                color={locationTitle ? 'primaryText' : 'muteMidGray_hover_6'}
                ml={6}
                size={14}
                font="400"
                height={18}
                isTruncated
              >
                {locationTitle || t('no_location_ent')}
              </OverflowTip>
            </Flex>
          </>
        )}

        <Flex>
          <HorizontalInfoBox
            className={classes.followersInfoWrapper}
            data={[
              {
                title: t('followers_cap'),
                value: isLoading ? (
                  <Skeleton style={{ width: 50, height: 21 }} />
                ) : (
                  followers || '0'
                ),
                onClick: !isPrivateAccount
                  ? (e) =>
                      openObjectNetworkModal(e, SocialConnectionsTabs.followers)
                  : undefined,
              },
              {
                title: t('following_cap'),
                value: isLoading ? (
                  <Skeleton style={{ width: 50, height: 21 }} />
                ) : (
                  following || '0'
                ),
                onClick: !isPrivateAccount
                  ? (e) =>
                      openObjectNetworkModal(e, SocialConnectionsTabs.following)
                  : undefined,
              },
              {
                title: t('posts'),
                value: isLoading ? (
                  <Skeleton style={{ width: 50, height: 21 }} />
                ) : (
                  postsCounter || '0'
                ),
                onClick: !isPrivateAccount ? onClickPosts : undefined,
              },
            ]}
          />
        </Flex>
        {isLoading ? (
          <Skeleton className={classes.actionSkeleton} />
        ) : (
          <>
            {isBlocked ? (
              <UnBlockButton
                id={id}
                entityType={isPage ? APP_ENTITIES.page : APP_ENTITIES.person}
                // remove={remove}
                undoBlock={undoBlock}
              />
            ) : null}
            {!!actions && !isBlocked ? (
              <Flex className={classes.actionsWrapper}>{actions}</Flex>
            ) : (
              <Flex className={classes.spacing} />
            )}
          </>
        )}
      </Flex>
    </Wrapper>
  );
};

export default ObjectCard;
