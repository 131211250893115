import classes from './styles.module.scss';

import React from 'react';
import { unfollowPeople, useReactMutation, useTranslation } from '@lobox/utils';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

export type UnfollowCallProps = {
  id: string;
  name: string;
  username: string;
  isPage?: boolean;
  needsAlert?: boolean;
};

type UseUnfollowUserType = Omit<UseMutationResult, 'mutate'> & {
  unfollowCall: (props: UnfollowCallProps, opt?: UseMutationOptions) => void;
};

const useUnfollowUser = (): UseUnfollowUserType => {
  const { t } = useTranslation();
  const { openConfirmDialog } = useOpenConfirm();
  const { mutate, ...rest } = useReactMutation({
    apiFunc: unfollowPeople,
  });

  const apiCall = (id: string, options?: UseMutationOptions) => () => {
    mutate(
      {
        id,
      }, // @ts-ignore
      options
    );
  };
  const unfollowCall = (
    { id, name, username, needsAlert = true }: UnfollowCallProps,
    options?: UseMutationOptions
  ): any => {
    if (needsAlert) {
      openConfirmDialog({
        title: (
          <Typography height={24} size={20} font="bold" isWordWrap>{`${t(
            'unfollow'
          )} ${name}`}</Typography>
        ),
        message: (
          <Flex className={classes.messageWrap}>
            <Typography>{t('unfollow_question')}</Typography>
            <Typography ml={4} font="bold">{`@${username} `}</Typography>
            <Typography>{t('q_mrk')}</Typography>
          </Flex>
        ),
        confirmButtonText: t('unfollow'),
        cancelButtonText: t('cancel'),
        confirmCallback: apiCall(id, options),
      });
    } else {
      apiCall(id, options)();
    }
  };

  return {
    unfollowCall,
    ...rest,
  };
};
;

export default useUnfollowUser;
